import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import $ from "jquery";
import Select from "react-select";
import Styles from "./viewpost.module.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import API from "../../../../Services/API";
import BreadCrumbs from "../../../../Components/Widgets/BreadCrumbs/BreadCrumbs";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {  Heading,  Box,  Image,  Text,  Input,  Textarea,  Divider,  Button,  Card,  CardHeader,  CardBody,  CardFooter,  Stack,  ButtonGroup,  Grid,
  GridItem,  StackDivider,  Flex,  Avatar, IconButton, FormControl,FormLabel,
} from "@chakra-ui/react";
import JoditEditor, { Jodit } from "jodit-react";
import parse from "html-react-parser";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import Author from "./Author/Author";
import PostDate from "./Date/Date";
import Tags from "./Tags/Tags";
import Pillars from "./Pillars/Pillars";
import Actions from "./Actions/Actions";
import Regions from "./Regions/Regions";
import Countries from "./Countries/Countries";
import BreadCrumbsNav from "../../../../Components/Shared/Navbar/BreadCrumbsNav";
import { EditContext } from "../../../../Context/BreadcrumbsContext";
import ErrorWidget from "../../../../Components/Widgets/ErrorWidget/ErrorWidget";
import PlaceholderImage from '../../../../../assets/images/userPlaceholder.svg'
import { DeleteIcon } from '@chakra-ui/icons'
import States from "./States/States";

const ViewPost = () => {
  const token = window.localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [editable, setEditable] = useState();
  const [viewPost, setViewPost] = useState();
  const { id } = useParams();
  const editor = useRef(null);
  const [file, setFile] = useState(null);
  const [blogTags, setBlogTags] = useState([]);
  const [currentBlog, setCurrentBlog] = useState([]);
  const [blogPillars, setBlogPillars] = useState([]);
  const [blogPillarsID, setBlogPillarsID] = useState(null);
  const [blogPillarsIDs, setBlogPillarsIDs] = useState(null);
  const [blogActions, setBlogActions] = useState([]);
  const [blogRegions, setBlogRegions] = useState([]);
  const [blogRegionsIds, setBlogRegionsIds] = useState([]);
  const [blogCountries, setBlogCountries] = useState([]);
  const [blogStatess, setBlogStatess] = useState([]);
  const {edit, setEdit,hasTagApplyPermission, hasCommentDeletePermission, user } = useContext(EditContext);

  
const config = {
  toolbarButtonSize: "middle",
  placeholder: "Start typing here...",
  buttons: [
    'bold', 'italic', 'underline', 'strikethrough', '|', 
    'ul', 'ol', '|', 'outdent', 'indent', '|', 
    'font', 'fontsize', 'brush', 'paragraph', '|', 
    'image', 'video', 'table', 'link', '|', 
    'align', 'undo', 'redo', '|', 
    'hr', 'eraser', 'copyformat', '|', 
    // Remove this option to hide the fullscreen button
  ],
  toolbarAdaptive: false,
  showFullScreen: false,
};

  const setBlogPillarsIdsforActions =(arrIds)=>{
    
    setBlogPillarsIDs(arrIds)
  }

  const setBlogRegionIdsforCountry =(arrIds)=>{
    
    setBlogRegionsIds(arrIds)
  }
  const [comments, setComments] = useState([])

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        setIsLoading(true)
        API.get(`/blog?id=${id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            setCurrentBlog(response.data)
            setBlogTags(response.data[0].blog_tag)
            setViewPost(response.data[0])
            setBlogActions(response.data[0].blog_action)
            setBlogPillars(response.data[0].blog_pillar)
            
            setBlogPillarsID(response.data[0].blog_pillar[response.data[0].blog_pillar.length-1]?.id)
            const ids =response.data[0].blog_pillar.map((a)=>a.id)
            setBlogPillarsIDs(Array.isArray(ids)&&ids)
            
            const regionIds =response.data[0].blog_region.map((a)=>a.id)
            
            setBlogRegionsIds(Array.isArray(regionIds)&&regionIds)
            setBlogRegions(response.data[0].blog_region)
            setBlogCountries(response.data[0].blog_country)
            setBlogStatess(response.data[0].blog_state)
            API.get(`/blog-comment?blog_id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response=>{
                setComments(response && response.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
        
    },[])

    const geteditStatus = (isEdit) =>{
        setEditable(isEdit)
    }

    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }


     const updateBlog = () => {
      setIsMessage(null); // Clear any previous message
      setIsLoading(true); // Set loading to true
  
      const formData = new FormData();
      if (file != null) {
          formData.append('cover_image', file);
      }
      formData.append('blog_id', parseInt(id));
      formData.append('title', $("#blogTitle").val());
      formData.append('content', $("#blogDescription").val());
      formData.append('author', $("#blogAuthor").val());
      formData.append('date', $("#blogDate").val());
      formData.append(
        "approval_status",
        "pending"
      );
  
      // Send PUT request to update the blog
      API.put(`/blog`, formData, {
          headers: {
              'Authorization': 'Bearer ' + token
          }
      })
      .then((response) => {
          setIsMessage('Post updated successfully');
          
          // After updating, fetch the updated blog data
          API.get(`/blog?id=${id}`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              }
          })
          .then((response1) => {
              setViewPost(response1.data[0]);
              setIsLoading(false); // Stop loading once data is fetched
          })
          .catch((error) => {
              setIsLoading(false);
          });
      })
      .catch((error) => {
          setIsMessage(error &&  error.response &&  error.response.data && error.response.data.msg);
          setIsLoading(false);
      });
  };
  

    const getUpdated = (updatedBlog) =>{
        // setIsMessage(true)
        setViewPost(updatedBlog)
        setBlogTags(updatedBlog.blog_tag)
        setBlogActions(updatedBlog.blog_action)
        setBlogPillars(updatedBlog.blog_pillar)
        setBlogRegions(updatedBlog.blog_region)
        setBlogCountries(updatedBlog.blog_country)
        setBlogStatess(updatedBlog.blog_state)

    }


    const postComment = async (event) => {
      setIsMessage(null)
      event.preventDefault();
      setIsLoading(true);
      const data = {
        blog_id: parseInt(id),
        comment: $("#blogComment").val(),
      };
      
      try {
        // Posting the comment as JSON
        await API.post('/blog-comment', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setIsMessage('Posted comment successfully')
        // Fetching the updated comments
        const response = await API.get(`/blog-comment?blog_id=${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const editor = Jodit.make("#blogComment");
        editor.value = "";
        setComments(response.data)
        } catch (error) {
            console.error("Error posting or fetching comment:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleDeleteComment = async (event,commentid) => {
      setIsMessage(null)
      event.preventDefault();
      setIsLoading(true);
      const data = {
        comment_id: parseInt(commentid),
      };
      try {
        // Posting the comment as JSON
        await API.delete('/blog-comment',  {
          data,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setIsMessage('Deleted comment successfully')
        // Fetching the updated comments
        const response = await API.get(`/blog-comment?blog_id=${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        // const editor = Jodit.make("#blogComment");
        // editor.value = "";
        setComments(response.data)
        } catch (error) {
            console.error("Error posting or fetching comment:", error);
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
      <>
        {isLoading ? <LoaderSpinner /> : null}
        <BreadCrumbs
          geteditStatus={geteditStatus}
          title={viewPost && viewPost.title}
        />
        {isMessage && isMessage ? (
          <ErrorWidget message={isMessage && isMessage} />
        ) : null}
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={4}
        >
          <GridItem colSpan={4}>
            <Card maxW="100%">
              {edit ? (
                <>
                  <CardBody>
                    <FormControl mb={"15px"}>
                      <FormLabel>Upload banner image</FormLabel>
                      <Input
                        type="file"
                        onChange={bannerUpload}
                        padding={"4px"}
                      />
                    </FormControl>
                    <FormControl mb={"15px"}>
                      <FormLabel> Title </FormLabel>
                      <Input
                        placeholder="Blog title"
                        type="text"
                        id="blogTitle"
                        defaultValue={viewPost && viewPost.title}
                      />
                    </FormControl>
                    <FormControl mb={"15px"}>
                      <FormLabel> Description </FormLabel>
                      <JoditEditor
                        id={"blogDescription"}
                        config={{placeholder:"Add description", readonly: false,
                          tabIndex: "0"}}
                        ref={editor}
                        value={viewPost && viewPost.content}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => {}}
                        className={Styles.joditeditorcustomheight}
                      />
                    </FormControl>
                    <FormControl mb={"15px"}>
                      <FormLabel> Author </FormLabel>
                      <Input
                        placeholder="Author name"
                        type="text"
                        id={"blogAuthor"}
                        defaultValue={viewPost && viewPost.author}
                      />
                    </FormControl>
                    <FormControl mb={"15px"}>
                      <FormLabel> Pick the date </FormLabel>
                      <Input
                        type="date"
                        id={"blogDate"}
                        defaultValue={viewPost && viewPost.date}
                      />
                    </FormControl>
                    {/* <FormControl mb={"15px"}>
                      <FormLabel> Value </FormLabel>
                      <Input
                        type="text"
                        id={"blogValue"}
                        defaultValue={
                          viewPost && viewPost.value !== null
                            ? viewPost && viewPost.value
                            : 0
                        }
                      />
                    </FormControl> */}
                    <FormControl mb={"15px"}>
                      <Button
                        onClick={updateBlog}
                        background={"#00aae0"}
                        color={"#ffffff"}
                      >
                        Save
                      </Button>
                    </FormControl>
                  </CardBody>
                </>
              ) : (
                <>
                  <CardBody>
                    {viewPost && viewPost.cover_image !== null ? (
                      <Image
                        src={viewPost && viewPost.cover_image}
                        alt={viewPost && viewPost.title}
                        borderRadius="lg"
                        width={"100%"}
                      />
                    ) : null}
                    <Stack mt="6" spacing="3">
                      <Heading size="md">{viewPost?.title || ""}</Heading>
                      <Box>
                        {typeof viewPost?.content === "string"
                          ? parse(viewPost.content)
                          : ""}
                      </Box>
                    </Stack>
                  </CardBody>
                  <Divider />

                  <CardFooter display={"block"} pt={"0"}>
                    {comments && comments.length > 0 ? (
                      <>
                        <FormLabel fontWeight={"bold"}> Comments </FormLabel>
                        {comments &&
                          comments.map((comment) => (
                            <>
                              <Flex spacing="4" p="4">
                                <Avatar name={comment.username} />
                                <Box flex="1" ml="4">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Heading size="sm">
                                      {comment?.username}
                                    </Heading>
                                    <Box display={'flex'} alignItems={'baseline'}>
                                      <Text className={Styles.sideBarText}>
                                        {formatDateTime(
                                          comment && comment?.created_at
                                        )}
                                      </Text>
                                     {(hasCommentDeletePermission || user?.id === comment?.created_by)&& <Button onClick={(e)=>handleDeleteComment(e,comment.id)}  variant={"contained"}>
                                        <DeleteIcon color={"#00a0da"}/>
                                      </Button>}
                                    </Box>
                                  </Flex>
                                  <Text>
                                    {typeof comment?.comment === "string"
                                      ? parse(comment.comment)
                                      : ""}
                                  </Text>
                                </Box>
                              </Flex>

                              <Divider />
                            </>
                          ))}
                      </>
                    ) : null}

                    <Box>
                      <FormControl mb={"15px"} width={"100%"}>
                        <FormLabel fontWeight={"bold"}>
                          {" "}
                          Leave A Comment{" "}
                        </FormLabel>
                        <JoditEditor
                          id={"blogComment"}   
                          ref={editor}
                          config={{
                            placeholder: "Leave a comment...",
                            readonly: false,
                            tabIndex: "0",
                          }}
                          value={""}
                          tabIndex={1}
                          onChange={(newContent) => {}}
                          className={Styles.joditeditorcustomheight}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb={"15px"}>
                        <Button
                          onClick={postComment}
                          background={"#00aae0"}
                          color={"#ffffff"}
                        >
                          Post Comment
                        </Button>
                      </FormControl>
                    </Box>
                  </CardFooter>
                </>
              )}
            </Card>
          </GridItem>
          <GridItem rowSpan={1} colSpan={2}>
            <Card height={"100%"}>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="1">
                  <Author author={viewPost && viewPost.author} />
                  <Box>
                    <Heading className={Styles.sideBarHeading}>
                      Compliance status
                    </Heading>
                    <Text p="0" className={Styles.sideBarText}>
                      {viewPost?.compliance_status
                        ? viewPost?.compliance_status
                        : ""}
                    </Text>
                  </Box>
                  <PostDate date={viewPost && viewPost.date} />
                  <Tags
                    editable={edit}
                    currentBlog={currentBlog}
                    selectedOptions={blogTags && blogTags}
                    id={id}
                    viewPost={viewPost}
                    getUpdated={getUpdated}
                  />
                  <Pillars
                    editable={edit}
                    selectedOptions={blogPillars && blogPillars}
                    id={id}
                    viewPost={viewPost}
                    setBlogPillarsIdsforActions={setBlogPillarsIdsforActions}
                    setBlogPillarsID={setBlogPillarsID}
                    getUpdated={getUpdated}
                  />
                  <Actions
                    editable={edit}
                    selectedOptions={blogActions && blogActions}
                    id={id}
                    blogPillarsIDs={blogPillarsIDs}
                    viewPost={viewPost}
                    blogPillarsID={blogPillarsID}
                    getUpdated={getUpdated}
                  />
                  <Regions
                    editable={edit}
                    selectedOptions={blogRegions && blogRegions}
                    setBlogCountries={setBlogCountries}
                    blogCountries={blogCountries}
                    id={id}
                    setBlogRegionIdsforCountry={setBlogRegionIdsforCountry}
                    viewPost={viewPost}
                    getUpdated={getUpdated}
                  />
                  <Countries
                    editable={edit}
                    selectedOptions={blogCountries && blogCountries}
                    setBlogCountries={setBlogCountries}
                    id={id}
                    blogRegionsIds={blogRegionsIds}
                    viewPost={viewPost}
                    getUpdated={getUpdated}
                  />
                  <States
                    editable={edit}
                    selectedOptions={blogStatess && blogStatess}
                    setBlogCountries={setBlogStatess}
                    id={id}
                    blogRegionsIds={blogCountries}
                    viewPost={viewPost}
                    getUpdated={getUpdated}
                  />
                </Stack>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </>
    );
}


function formatDateTime(isoString) {
  const date = new Date(isoString);  // Use native JavaScript Date object

  // Extract components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  // Format time in 12-hour format
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock

  return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
}


export default ViewPost
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Styles from "./newpopup.module.css";
import API from "../../../../../../Services/API";
import { AddIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Card,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Textarea,
  Select,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import ErrorWidget from "../../../../../../Components/Widgets/ErrorWidget/ErrorWidget";
import LoaderSpinner from "../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";

const NewPopup = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addAnother, setAddAnother] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);
  const [errormsg, seterrormsg] = useState(null);
  const [errorname, seterrorname] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [fileType, setFileType] = useState(""); // Track file type
  const [inputType, setInputType] = useState("file"); // Track input choice (File or URL)

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };
useEffect(()=>{
  seterrormsg(null);
},[isOpen])
  const handleInputTypeChange = (value) => {
    setInputType(value);
  };

  const saveAndAddNew = (event) => {
    setAddAnother(event.target.checked);
  };

  const newArticle = () => {
    const allowedExtensions = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    seterrorname(false)
    seterrormsg(null)
    if($("#name").val()==""){
      seterrorname(true)
      return
    }
    if(inputType==="file"){
    if($("#resource")[0].files[0]?.type !== 'video/mp4' && fileType==="video" ){
      seterrormsg("Please upload a video in mp4 format.")
      return
    }
    if(!allowedExtensions.includes($("#resource")[0].files[0]?.type) && fileType==="document" ){
      seterrormsg("Please upload documents in PDF or word format.")
      return
    }}
    

    setMessage(null);
    setIsLoading(true);
    seterrorMessage(null);

    const formData = new FormData();
    formData.append("file_choices", $("#file_choices").val()); // File type (document or video)
    formData.append("name", $("#name").val()); // Guide/Video name

    if (inputType === "file") {
      // Add file input
      formData.append("resource", $("#resource")[0].files[0]);
    } else if (inputType === "url") {
      // Add URL input
      formData.append("url", $("#resource_url").val());
    }

    formData.append("note", $("#note").val()); // Optional note

    API.post(`/guides`, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (addAnother) {
          setIsLoading(false);
          onOpen(); // Keep the modal open for adding another
          $("#file_choices").val(""); // Clear the file type field
          $("#name").val(""); // Clear the name field
          $("#resource").val(""); // Clear the resource file input
          $("#resource_url").val(""); // Clear the resource URL input
          $("#note").val(""); // Clear the note field

          API.get(`/guides`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => {
              setMessage("Training guide/video has been added successfully");
              props.getUpdated(response.data); // Update the parent component
            })
            .catch((error) => {
              console.error("Error fetching updated articles:", error);
              setIsLoading(false);
            });
        } else {
          onClose(); // Close the modal
          setIsLoading(true);
          API.get(`/guides`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => {
              setIsLoading(false);
              setMessage("Training guide/video has been added successfully.");
              props.getUpdated(response.data); // Update the parent component
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Error fetching updated articles:", error);
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error submitting article:", error.response?.data?.error);
        seterrorMessage(
          error?.response?.data?.error || "Something went wrong"
        );
        onClose();
      });
  };

  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {message && <ErrorWidget message={message} />}
      {errorMessage && <ErrorWidget message={errorMessage} />}
      <Box className="cardHover" mb={"30px"}>
        <Card tabIndex={"0"}
          sx={{
            cursor: "pointer",
            transition: "0.3s",
            "&:focus": {
              outline: "2px solid #00AAE0", // Highlight border on focus
              boxShadow: "0 0 5px rgba(0, 170, 224, 0.7)", // Optional glow effect
            },
          }}
          onKeyDown={(event) => {
            // Only handle Enter key when the card is focused and not the delete icon
            if (event.key === "Enter" && event.target === event.currentTarget) {
              event.preventDefault();   
              event.stopPropagation();
             onOpen();
            }
          }}
          height="100px"
          bg="white"
          position={"relative"}
          cursor={"pointer"}
          onClick={onOpen}
        >
          <Heading
            as="h1"
            size="sm"
            noOfLines={1}
            position={"relative"}
            top={"50%"}
            className={Styles.verticalAlign}
            textAlign={"center"}
          >
            <AddIcon boxSize={3} /> Add New
          </Heading>
        </Card>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay className={Styles.overLay} />
        <ModalContent className={Styles.contentZindex}>
          <ModalHeader>Add New Guides / Videos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel mt="10px">Name</FormLabel>
              <Input
                type="text"
                id="name"
                mb="15px"
                placeholder="Enter name"
              />
              {errorname && <Text color={"red"}>{"Name is required."}</Text>}
              <FormLabel>Type</FormLabel>
              <Select
                id="file_choices"
                mb="15px"
                placeholder="Select type"
                value={fileType}
                onChange={handleFileTypeChange}
              >
                <option value="document">Document</option>
                <option value="video">Video</option>
              </Select>

              <FormLabel mt="10px">Input Type</FormLabel>
              <RadioGroup onChange={handleInputTypeChange} value={inputType}>
                <Stack direction="row">
                  <Radio value="file">File</Radio>
                {(fileType !== "document") &&  <Radio value="url">URL</Radio>}
                </Stack>
              </RadioGroup>

              {(inputType === "file") && (
                <>
                  <FormLabel mt="10px">Resource</FormLabel>
                  <Input type="file" id="resource" mb="15px" disabled={!fileType} />
                  { errormsg && <Text color={"red"}>{errormsg}</Text>}
                </>
              )}

              {(inputType === "url") && (
                <>
                  <FormLabel mt="10px">Resource URL</FormLabel>
                  <Input
                    type="url"
                    id="resource_url"
                    mb="15px"
                    placeholder="Enter URL"
                  />
                </>
              )}

              <FormLabel mt="10px">Note</FormLabel>
              <Textarea
                id="note"
                mb="15px"
                placeholder="Enter any additional notes"
              />

              <Stack spacing={5} direction="row" mt="10px">
                <Checkbox
                  colorScheme="green"
                  defaultChecked={addAnother}
                  onChange={saveAndAddNew}
                >
                  Save and Add Another
                </Checkbox>
              </Stack>
            </FormControl>
          </ModalBody>
          <ModalFooter className={Styles.modelFooter}>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="ghost" onClick={newArticle}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewPopup;

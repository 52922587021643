// import React, {useEffect} from 'react'
// import Styles from './listItem.module.css'
// import { Link, useParams } from 'react-router-dom';
// import { Heading, Card, Box,} from '@chakra-ui/react'
// import DeleteFaq from './DeleteFaq/DeleteFaq';

// const ListItem = (props) => {
//     const {name} = useParams();
//     const {id} = useParams();

//     return (
//         <>
//             <Box mb={'30px'}>
//                 {
//                     props.editable ?
//                         <DeleteFaq id={props.id} title={props.faqName} getUpdated={props.getUpdated} />
//                     : null
//                 }
//                 <Link className={props.editable ? Styles.customLinkButtonEdit : Styles.customLinkButton} to={`/help/faq/${id}/${name}/view/${props.id}/${props.faqName}`}>
//                     <Card height='100px' bg='white' position={'relative'} cursor={'pointer'} key={props.key} id={props.id} overflow={'hidden'} title={props.article_name}>
//                         <Heading 
//                             as='h1' 
//                             size='sm' 
//                             noOfLines={2} 
//                             position={'relative'} 
//                             top={'50%'} 
//                             className={Styles.verticalAlign}
//                             textAlign={'left'}
//                             marginLeft={'10px'}
//                         >
//                             {props.faqName}
//                         </Heading>
//                     </Card>                    
//                 </Link>
//             </Box>
//         </>
//     )
// }

// export default ListItem


import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Heading,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import DeleteFaq from './DeleteFaq/DeleteFaq';

const ListItem = (props) => {
  const { name, id } = useParams();

  return (
    <Box mb="10px">
      <Accordion allowToggle>
        <AccordionItem
          border="1px solid"
          borderColor="gray.300"
          borderRadius="md"
          background="#f7f7f7"
        >
          {/* Accordion Button */}
          <AccordionButton
            as="div"
            role="button"
            tabIndex={0}
            aria-expanded={props.isExpanded}
            aria-controls={`faq-panel-${props.id}`}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                event.currentTarget.click(); // Trigger click on Enter/Space
              }
            }}
          >
            {/* Link to FAQ */}
            <Box as="span" flex="1" textAlign="left">
              <Link
                to={`/help/faq/253/FAQs/view/${props.id}/${props.faqName}`}
                aria-label={`Open FAQ: ${props.faqName}`}
                tabIndex={0}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {props.faqName}
              </Link>
            </Box>

            {/* Expand Icon */}
            <AccordionIcon
              role="button"
              aria-label="Expand FAQ"
              tabIndex={0}
              sx={{
                cursor: "pointer",
                transition: "0.3s",
                "&:focus": {
                  outline: "2px solid #00AAE0", // Highlight border on focus
                  boxShadow: "0 0 5px rgba(0, 170, 224, 0.7)", // Optional glow effect
                },
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault();
                  event.currentTarget.click(); // Trigger click on Enter/Space
                }
              }}
              mr={5}
            />

            {/* Delete Icon */}
            {props.editable && (
              <DeleteFaq
                id={props.id}
                title={props.faqName}
                getUpdated={props.getUpdated}
              />
            )}
          </AccordionButton>

          {/* Accordion Content */}
          <AccordionPanel
            id={`faq-panel-${props.id}`}
            padding="20px"
            bg="white"
          >
            <Heading
              as="h1"
              fontWeight="500"
              size="sm"
              textAlign="left"
              fontSize={{ base: 'sm', md: 'md' }}
              sx={{
                '& div': {
                  fontWeight: 'normal'
                }
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: props.faqdes.description }}
              />
            </Heading>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ListItem;

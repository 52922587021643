import React, { useEffect, useState, useContext } from 'react';
import { EditContext } from '../../../Context/BreadcrumbsContext';
import { Link } from 'react-router-dom';
import Styles from './Aspirations.module.css'
import { TableContainer, Box, Text, Spinner, Table, Thead, Tr, Th, Tbody, Td, Button } from '@chakra-ui/react';
import API from '../../../Services/API';
import { TabContainer } from 'react-bootstrap';
import EditAspiration from './EditAspiration/EditAspiration';
import DeleteComponent from './DeleteComponent/DeleteComponent'
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Aspirations = () => {
    const token = window.localStorage.getItem("accessToken")
    const [aspirations, setAspirations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useContext(EditContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        API.get(`/my-task-aspiration`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedMetrics = response.data.results.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setAspirations(sortedMetrics)
            setTotalPages(response.data.total_pages);
            setCurrentPage(response.data.current_page);
        }).catch(error=>{
        })
    }, []);

    const getAspiration = () =>{
        API.get(`/my-task-aspiration`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setAspirations(sortedMetrics)
        }).catch(error=>{
        })
    }

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
          setCurrentPage(page);
        }
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Name</Th>
                            <Th className={Styles.th} color="blue.600">Value</Th>
                            <Th className={Styles.th} color="blue.600">Period - Year</Th>
                            <Th className={Styles.th} color="blue.600">Period - Qtr</Th>
                            <Th className={Styles.th} color="blue.600">Approval</Th>
                            <Th className={Styles.th} color="blue.600">Reject Reason</Th>
                            <Th className={Styles.th} color="blue.600">Approve</Th>
                            {user?.action_office_user || user?.is_superuser ? <Th className={Styles.th} color="blue.600">Action</Th> : null}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            aspirations && aspirations.map((aspiration, index) =>
                                <Tr className={Styles.tr}>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'} color={'#0070d2'} textDecoration={'underline'}>
                                            <Link to={`/mytasks/metric/${aspiration.metric}/${encodeURIComponent(aspiration.name)}#aspirations`}> {aspiration.name} </Link>
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.value} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.target_fiscal_year_name} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.target_fiscal_quarter_name} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.approval_status} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                          {aspiration.approval_status === "rejected" ? <>{aspiration.reject_reason}</> : null}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} textAlign={'center'}> 
                                        <ApproveComponent id={aspiration.id} getAspiration={getAspiration} />
                                        <RejectComponent id={aspiration.id} getAspiration={getAspiration} url={`/mytasks/metric/${aspiration.metric}/${encodeURIComponent(aspiration.name)}#metricsettings`} />
                                    </Td>
                                    {user?.action_office_user || user?.is_superuser ? 
                                        <Td className={Styles.td+' actionButton'} textAlign={'center'}>
                                            <EditAspiration aspiration={aspiration} getAspiration={getAspiration} />
                                            <DeleteComponent id={aspiration.id} getAspiration={getAspiration} />
                                        </Td>
                                    : null}
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        mx={1}
                        aria-label="Previous Page"
                    >
                        <ChevronLeftIcon />
                    </Button>
                    {[...Array(totalPages)].map((_, page) => (
                        <Button
                            key={page}
                            onClick={() => handlePageChange(page + 1)}
                            isActive={currentPage === page + 1}
                            mx={1}
                        >
                            {page + 1}
                        </Button>
                    ))}
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        mx={1}
                        aria-label="Next Page"
                    >
                        <ChevronRightIcon />
                    </Button>
                </Box>
            </TableContainer>
        </>
    )
}

export default Aspirations
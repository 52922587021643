import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Styles from './createmetric.module.css';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, SimpleGrid, Switch, Textarea } from '@chakra-ui/react';
import {AddIcon} from '@chakra-ui/icons'

import API from '../../../../Services/API';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import JoditEditor from 'jodit-react';
import ErrorWidget from '../../../Widgets/ErrorWidget/ErrorWidget';

const CreateMetric = (props) => {
    const token = window.localStorage.getItem('accessToken');
    const [state, setState] = useState({ isPaneOpen: false });
    const [isLoading, setIsLoading] = useState(false);
    const editor = useRef(null);
    const [message, setMessage] = useState(false);
    const [pillarAction, setPillarAction] = useState([]);
    const [strategy, setStrategy] = useState(null);
    const [strategies, setStrategies] = useState([]);
    const [isQbr, setIsQbr] = useState(false);
    const [isMap, setIsMap] = useState(false);
    const [allow, setAllow] = useState(false);
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);

    const [selectedDataType, setSelectedDataType] = useState(null);
    const [selectedMetricType, setSelectedMetricType] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [tagDetails, setTagDetails] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedAggregate, setSelectedAggregate] = useState(null);
    const [formErrors, setFormErrors] = useState({
        metricName: '',
        dataType: '',
        metricType: '',
        aggregate: '',
    });
    const [user, setUser] = useState({})

    useEffect(() => {
        setIsLoading(true);
        API.get(`/get-specific-modules?module_id=${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setUser(response.data);
        }).catch(error => {
            setIsLoading(false);
        });

        API.get(`/pillar?module_id=${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(response => {
            const pillarId = response.data.id;
            return Promise.all([
                API.get(`/action?pillar_id=${pillarId}`, { headers: { 'Authorization': 'Bearer ' + token } }),
            ]);
        })
        .then(([actionsResponse]) => {
            setPillarAction(actionsResponse.data);
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            setIsLoading(false);
        });
        API.get(`/tag-detail/`, {
            headers: { 'Authorization': 'Bearer ' + token },
          })
        .then((response) => {
          setTagDetails(response.data.results)
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
        
    }, [id, token, state.isPaneOpen]);

    const resetForm = () => {
        // Reset all form fields
        setSelectedTags([]);
        setSelectedDataType(null);
        setSelectedMetricType(null);
        setSelectedAction(null);
        setSelectedAggregate(null);
        setAllow(false);
        document.getElementById('metricname').value = '';
        document.getElementById('metricdescription').value = '';

        // Reset validation errors
        setFormErrors({
            metricName: '',
            dataType: '',
            metricType: '',
            aggregate: '',
        });
    };

    const openSlidingPane = () => {
        setState({ isPaneOpen: true });
    };
    const closeSlidingPane = () => {
        setState({ isPaneOpen: false });
        resetForm();
    };

    const handleSwitchChange = (setter) => (event) => {
        setter(event.target.checked);
    };

    const validateForm = () => {
        const errors = {};
        if (!document.getElementById('metricname').value) {
            errors.metricName = 'Metric name is required.';
        }
        if (!selectedDataType) {
            errors.dataType = 'Data type is required.';
        }
        if (!selectedMetricType) {
            errors.metricType = 'Metric type is required.';
        }
        if (!selectedAggregate) {
            errors.aggregate = 'Aggregate is required.';
        }
        return errors;
    };

    const newMetric = () => {
        setMessage(null)
        const errors = validateForm();
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", document.getElementById('metricname').value);
        formData.append("description", document.getElementById('metricdescription').value);
        formData.append("datatype", selectedDataType.value);
        formData.append("metrictype", selectedMetricType.value);
        formData.append("aggregate", selectedAggregate);
        formData.append("map", isMap);
        formData.append("pillar_id", props.pillarMetricId);
        formData.append("allow_export_externally", allow);
        formData.append("dashboard", true);
        formData.append("elr", isQbr);
        formData.append('metric_tag', JSON.stringify(selectedTags.map((tag) => tag.value)))

        if(strategy){
            formData.append("strategy", strategy.value);
        }

        if (selectedAction) {
            formData.append("action_id", selectedAction.value);
        }

        if(user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'){
            formData.append('approval_status', 'approved');
        }else{
            formData.append('approval_status', 'pending');
        }

        if(user.superuser){
            formData.append('position', 'superuser');
        }else if(user.action_office_user){
            formData.append('position', 'action_office_user');
        }else if(user.comms_user){
            formData.append('position', 'comms_user');
        }else if(user.elt_user){
            formData.append('position', 'elt_user');
        }else if(user.position === 'pillar_owner'){
            formData.append('position', 'pillar_owner');
        }else if(user.position === 'pillar_contributor'){
            formData.append('position', 'pillar_contributor');
        }else if(user.position === 'pillar_member'){
            formData.append('position', 'pillar_member');
        }else if(user.position === 'action_owner'){
            formData.append('position', 'action_owner');
        }else if(user.position === 'action_contributor'){
            formData.append('position', 'action_contributor');
        }else if(user.position === 'action_member'){
            formData.append('position', 'action_member');
        }

        

        if(user.position === 'pillar_owner' || user.position === 'action_owner'){
            formData.append('position_of_user', 'owner');
        }else if(user.position === 'action_contributor' || user.position === 'pillar_contributor'){
            formData.append('position_of_user', 'contributor');
        }

        API.post(`/metric`, formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(() => {
            setState({ isPaneOpen: false });
            if(user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'){
                setMessage('Thank you! Please refresh the screen to view the updated data.');
            }else{
                setMessage('Thank you! Your data has been successfully submitted for approval.');
            }
            props.getUpdatedMetrics()
        })
        .catch(error => {
            if(error.response.data.msg){
                setErrorMessage(error.response.data && error.response.data.msg);
            }else if(error.response.data.name) {
                setErrorMessage(error.response.data && error.response.data.name[0]);
            } else {
                setErrorMessage('Apologies for the inconvenience, but an error has occurred. If this issue persists, please reach out to our support team at support@purposefy.com.');
            }
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const dataTypes = [
        { value: 'percent', label: 'Percent' },
        { value: 'number', label: 'Number' },
        { value: 'money', label: 'Money' },
    ];

    const metricTypes = [
        { value: 'operational', label: 'Operational' },
        { value: 'financial', label: 'Financial' },
        { value: 'impact', label: 'Impact' },
    ];

    const actions = pillarAction.filter(item => item.visible).map(item => ({
        value: item.id,
        label: item.name,
    }));

    const selectAction = (event)=>{
        if(!event){
            return
        }
        setSelectedAction(event)
        API.get(`/strategy?action_id=${event.value}`, { 
            headers: { 'Authorization': 'Bearer ' + token } 
        })
        .then(response => {
            setStrategies(response.data)
        })
        .catch(error => {
            console.error('Error fetching strategy:', error);
        });
    }

    // const strategiesOptions = strategies.map((strategy) => ({
    //     value: strategy.id,
    //     label: strategy.name,
    // }));

    const strategiesOptions = strategies.map((strategy) => ({
        value: strategy.id,
        label: strategy.name,
    })).sort((a, b) => a.label.localeCompare(b.label));

    const selectStrategy = (event) =>{
        setStrategy(event);
        if(!event){
            return
        }
    }
    const aggregateTypes = [
        { value: 'sum', label: 'Sum' },
        { value: 'avg', label: 'Avg' },
        { value: 'max', label: 'Max' },
        { value: 'last', label: 'Last' },
    ];

    const selectAggregate = (event) => {
        setSelectedAggregate(event.value);
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' float={'right'} onClick={openSlidingPane}>
                Add Metric
            </Button>
            {message && <ErrorWidget message={message} />}
            
            <SlidingPane
                className={Styles.slidingPane}
                isOpen={state.isPaneOpen}
                title="Create Metric"
                width="50%"
                onRequestClose={closeSlidingPane}
            >
                {errorMessage && <Box color={'red'}>{errorMessage}</Box>}
                <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'}>
                    <Box>
                        <FormControl isInvalid={!!formErrors.metricName}>
                            <FormLabel fontWeight={'bold'}>
                                Name <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Input 
                                type='text'
                                id='metricname'
                                placeholder='Please provide metric name'
                            />
                            {formErrors.metricName && <Box color='crimson'>{formErrors.metricName}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.dataType}>
                            <FormLabel fontWeight={'bold'}>
                                Data Type <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select
                                onChange={setSelectedDataType}
                                options={dataTypes}
                                isClearable
                            />
                            {formErrors.dataType && <Box color='crimson'>{formErrors.dataType}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.metricType}>
                            <FormLabel fontWeight={'bold'}>
                                Metric Type <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select
                                onChange={setSelectedMetricType}
                                options={metricTypes}
                                isClearable
                            />
                            {formErrors.metricType && <Box color='crimson'>{formErrors.metricType}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.aggregate}>
                            <FormLabel fontWeight={'bold'}>
                                Aggregate <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select onChange={selectAggregate} options={aggregateTypes} isClearable />
                            {formErrors.aggregate && <Box color='crimson'>{formErrors.aggregate}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Action</FormLabel>
                            <Select
                                onChange={selectAction}
                                options={actions}
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Strategy</FormLabel>
                            <Select
                                onChange={selectStrategy}
                                options={strategiesOptions}
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Tag</FormLabel>
                            <Select
                                isMulti // Allow multi-selection
                                value={selectedTags}
                                onChange={setSelectedTags} // Handle array of selected tags
                                options={tagDetails.map((tag) => ({
                                    value: tag.id,
                                    label: tag.name,
                                }))}
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricqbr' mb='0'>
                                Executive Leadership Review
                            </FormLabel>
                            <Switch id='metricqbr' onChange={handleSwitchChange(setIsQbr)} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricmap' mb='0'>
                                Map 
                            </FormLabel>
                            <Switch id='metricmap' onChange={handleSwitchChange(setIsMap)} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='share_data_externally' mb='0' fontWeight={'bold'}>
                                Share Data Externally
                            </FormLabel>
                            <Switch id='share_data_externally' onChange={handleSwitchChange(setAllow)} />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <FormControl mt={4}>
                    <FormLabel fontWeight={'bold'}>Description</FormLabel>
                    <Textarea id='metricdescription' placeholder='Provide a brief description of the metric' />
                </FormControl>
                <Box mt={4}>
                    
                    <Button onClick={() => setState({ isPaneOpen: false })} mr={3}>
                        Cancel
                    </Button>
                    <Button onClick={newMetric}  colorScheme='blue'>
                        Create Metric
                    </Button>
                </Box>
            </SlidingPane>
        </>
    );
};

export default CreateMetric;

import React, { useEffect, useState, useContext } from 'react';
import Styles from '../qbr.module.css'
import { Box, Text, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import API from '../../../../Services/API';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import { TabContainer } from 'react-bootstrap';
import ApprooveComponent from './ApprooveComponent/ApprooveComponent';
import RejectComponent from './RejectComponent/RejectComponent';
import View from './View/View';

const ProgressNextSteps = () => {
    const token = window.localStorage.getItem("accessToken")
    const [data, setData] = useState([])
    const { user } = useContext(EditContext);
    const [loading, setLoading] = useState(false);
      
    useEffect(()=>{
        getUpdatedRecords()
    },[])

    const getUpdatedRecords = ()=>{
        API.get(`/mytask-qbr-screen-status?screen=2`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response=>{
            setData(response.data)
        })
        .catch(error=>{
        })
    }


    return (
        <>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Fiscal Year</Th>
                            <Th className={Styles.th} color="blue.600">Action</Th>
                            <Th className={Styles.th} color="blue.600">Execution Status</Th>
                            <Th className={Styles.th} color="blue.600">Updated By</Th>
                            <Th className={Styles.th} color="blue.600">Approval Status</Th>
                            <Th className={Styles.th} color="blue.600">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data && data.map(item=>
                                <Tr className={Styles.tr}>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        {item.fy_name ? parse(item.fy_name) : ''} 
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap" color={'#0070d2'} textDecoration={'underline'}>
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {/* {item.action_name ? parse(item.action_name) : ''} */}
                                            <Link  to={`action/${item.module_id}/${encodeURIComponent(item.action_name)}#qbr`}>
                                              {item.action_name ? parse(item.action_name) : ''}
                                            </Link>
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                        {item.screen_status}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                        {item.updated_by_name}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                        {item.approval_status}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            <ApprooveComponent
                                                id={item.id}
                                                goals={item.goals} 
                                                achievements={item.achievements_to_date} 
                                                nextSteps={item.next_steps} 
                                                strategy={item.strategy}
                                                strategyName={item.strategy_name}
                                                action={item.action}
                                                //strategiesOptions={strategiesOptions} 
                                                getUpdatedRecords={getUpdatedRecords}
                                            />
                                            <RejectComponent
                                                id={item.id}
                                                goals={item.goals} 
                                                achievements={item.achievements_to_date} 
                                                nextSteps={item.next_steps} 
                                                strategy={item.strategy}
                                                strategyName={item.strategy_name}
                                                action={item.action}
                                                //strategiesOptions={strategiesOptions} 
                                                getUpdatedRecords={getUpdatedRecords}
                                            />
                                            <View
                                                paneltitle={item.action_name}
                                                fiscalyear={item.fiscal_year}
                                                action={item.action}
                                                actionExecutionStatus={item.screen_status}
                                            />
                                        </Box>
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ProgressNextSteps
import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from './delete.module.css'
import { DeleteIcon, PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import API from '../../../../../Services/API';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Text,
    Button,
    Box
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget'

const DeleteMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] =useState(false)

    const deleteMetric = (event) =>{
        props.isLoaderloading(true)
        const formData = new FormData()
        formData.append("visible", false)
        formData.append("id", parseInt(props.id))
        API.put(`/metric`,formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            if(props.filterStatus === 1){
                API.get(`/metric-filter?query=${props.type}&fiscal_${props.optionName}=${props.optionValue}`, {
                  headers: {
                    'Authorization': 'Bearer ' + token
                  }
                }).then(response1=>{
                    onClose()
                    props.getUpatedMetric(response1.data)
                    props.isLoaderloading(false)
                }).catch(error=>{
                    props.isLoaderloading(false)
                })
            }else if(props.type === 'allmetrics'){
                API.get(`/metric`, {
                  headers: {
                    'Authorization': 'Bearer ' + token
                  }
                }).then(response1=>{
                    onClose()
                    props.getUpatedMetric(response1.data)
                    props.isLoaderloading(false)
                }).catch(error=>{
                    onClose()
                    props.isLoaderloading(false)
                })
            }else{
                API.get(`/metric?query=${props.type}`, {
                  headers: {
                    'Authorization': 'Bearer ' + token
                  }
                }).then(response1=>{
                    onClose()
                    props.getUpatedMetric(response1.data)
                    props.isLoaderloading(false)
                }).catch(error=>{
                    onClose()
                    props.isLoaderloading(false)
                })
            }
        })
    }

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
             <Box
                className="frontFlipIcon"        
                tabIndex="0"
                role="button"
                onClick={onOpen}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-end",
                    // alignItems: "end",
                    transition: "0.3s",
                    "&:focus": {
                        outline: "4px solid #00AAE0",
                        boxShadow: "0 0 5px rgba(0, 170, 224, 0.7)",
                    },
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                        event.preventDefault();
                        onOpen();
                    }
                }}
            >
            <DeleteIcon          onClick={onOpen} />
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text className={Styles.contentInfo}>
                          Are you sure you want to delete it? This action cannot be reversed.
                        </Text>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={Styles.confirmButton} id={props.id} onClick={deleteMetric}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteMetric
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../MetricData/metricdata.module.css'
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import API from '../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import Select from 'react-select';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import View from './View/View';

const ExecutiveLevelReview = () => {
  const token = window.localStorage.getItem("accessToken")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [elrSectionOne, setElrSectionOne] = useState()
  const [elrMetric, setElrMetric] = useState()
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [impactStories, setImpactStories] = useState(null);
  const [elr, setElr] = useState(null);
  const [noData, setNoData] = useState(0);
  const editor = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [newErrorMessage, setNewErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  useEffect(()=>{
    setIsLoading(true)
    API.get(`/executive-level-review`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setElrSectionOne(response.data)
      setPreview(response.data && response.data[0].banner)
      setViewOneId(response.data && response.data.id)
      setIsLoading(false)
      setNoData(0)
    }).catch(error=>{
      setNewErrorMessage(error.response.data.error)
      setIsLoading(false)
      setNoData(1)
    })
  },[])

  const getUpdatedPending = (metricTask) =>{
    setElrSectionOne(metricTask)
    setPreview(metricTask && metricTask[0].banner)
    setViewOneId(metricTask && metricTask.id)
    setIsLoading(false)
    setNoData(0)
    setErrorMessage(null)
    setSuccessMessage('Status updated successfully')
  }

  const getErrorUpdate = (error) =>{
  
    setErrorMessage(error)
  }
  const filteredData = elrSectionOne && elrSectionOne.filter(item => item.approval_status === 'Submitted' || item.approval_status === 'submitted' || item.approval_status === 'Approved');

  const getNewUpdate = (response) =>{
    setElrSectionOne(response.data)
    setPreview(response.data && response.data[0].banner)
    setViewOneId(response.data && response.data.id)
    setIsLoading(false)
    setNoData(0)
  }

  const getErrorUpdateNew = (error) =>{
    setNewErrorMessage(error.response.data.error)
    setIsLoading(false)
    setNoData(1)
  }
  
  return (
    <>
    {isLoading ? <LoaderSpinner /> : null}
    {errorMessage && errorMessage !== null ? <Box p={'5px'} color={'red'}> {errorMessage && errorMessage} </Box>:null}
    {successMessage && successMessage !== null ? <Box p={'5px'} color={'#00a0da'}> {successMessage && successMessage} </Box>:null}
    {
      newErrorMessage && newErrorMessage ? <Box fontSize={'13px'}> {newErrorMessage} </Box> :
      <TableContainer>
        <Table size='sm'>
          <Thead>
            <Tr className={Styles.tr}>
              <Th className={Styles.th}>Period - Year</Th>
              <Th className={Styles.th}>Period - Qtr</Th>
              <Th className={Styles.th}>Executive level review </Th>
              <Th className={Styles.th}>SIIO/Action Name</Th>
              <Th className={Styles.th}>Created On</Th>
              <Th className={Styles.th}>Updated On</Th>
              <Th className={Styles.th}>Created By</Th>
              <Th className={Styles.th}>Approval Status</Th>
              <Th className={Styles.th}>Approve</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              filteredData && filteredData.length === 0 ? (
                <Tr>
                  <Td colSpan="8">There is no data available.</Td>
                </Tr>
              ):(
                filteredData && filteredData.map((item, index)=>
                  <Tr className={Styles.tr}>
                    <Td className={Styles.td}>
                      {item.fiscal_year}
                    </Td>
                    <Td className={Styles.td}>
                      {item.fiscal_quarter}
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} display={'block'} whiteSpace={'normal'} color={'#0070d2'}>
                        <Link to={`action/${item.module_id}/${encodeURIComponent(item.action_name)}#executiveLevelReview#executiveleadershipreviews`}>
                          {item.label}
                        </Link>
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} display={'block'} whiteSpace={'normal'} color={'#0070d2'}>
                        {
                          item.type === 'pillar' ?
                          <Link to={`/pillar/${item.module_id}/${encodeURIComponent(item.pillar_name)}`}>
                            {item.pillar_name}
                          </Link> : 
                          <Link to={`action/${item.module_id}/${encodeURIComponent(item.action_name)}#executiveleadershipreviews`}>
                            {item.action_name}
                          </Link>
                        }
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      {new Date(item.created_at).toLocaleDateString()}
                    </Td>
                    <Td className={Styles.td}>
                      {new Date(item.updated_at).toLocaleDateString()}
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} display={'block'} whiteSpace={'normal'}>
                        {item.user}
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      {item.approval_status}
                    </Td>
                    <Td className='actionButton'>
                      <Stack direction='row' spacing={0} align='center'>
                        <ApproveComponent 
                          id={item.id} 
                          getUpdatedPending={getUpdatedPending} 
                          defaultSelected={item.approval_status} 
                          pillar={item.pillar} 
                          getErrorUpdate={getErrorUpdate}
                          type={item.type}
                          action={item.action}
                        />
                        <RejectComponent 
                          id={item.id} 
                          getUpdatedPending={getUpdatedPending} 
                          defaultSelected={item.approval_status} 
                          pillar={item.pillar} 
                          getErrorUpdate={getErrorUpdate}
                          type={item.type}
                          action={item.action}
                        />
                        <View
                          id={item.id}
                          pillar={item.action}
                          title={item.label}
                          action={item.action}
                          getNewUpdate={getNewUpdate}
                          getErrorUpdateNew={getErrorUpdateNew}
                        />
                      </Stack>
                    </Td>
                  </Tr>
                )
              )
            }
          </Tbody>
        </Table>
      </TableContainer>
    }
  </>
  )
}

export default ExecutiveLevelReview
import React, { useState, useEffect } from 'react';
import { IconButton, Switch, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, FormControl, FormLabel, Input, Grid, Box, useToast, Tooltip, } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Select from 'react-select';
import API from '../../../../../Services/API';

const customStyles = {
    control: (base) => ({
        ...base,
        borderColor: '#CBD5E0',
        borderRadius: '0.375rem',
        padding: '2px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#3182CE',
        },
    }),
    menu: (base) => ({
        ...base,
        zIndex: 5,
    }),
};

const EditUser = ({ id, user, onSave, getUpdateUser }) => {
    const token = window.localStorage.getItem('accessToken');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', role: '' });
    const toast = useToast();
    const [pillars, setPillars] = useState([]);
    const [actions, setActions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [actionOfficeUser, setActionOfficeUser] = useState(false);
    const [eltUser, setEltUser] = useState(false);
    const [commsUser, setCommsUser] = useState(false);
    const [selectedPillar, setSelectedPillar] = useState(null);
    const [level, setLevel] = useState(null);
    const [selectedPillarId, seSelectedPillarId] = useState(null);
    const [permissionType, setPermissionType] = useState(null);
    const [message, setMessage] = useState(null);
    const [selectedDashboardvisible, setSelectedDashboardvisible] = useState(false);
    const [selecteddasboardposition, setSelecteddasboardposition] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [userDetails, setUserDetails] = useState({
        jobTitle: '',
        webexName: '',
        webexURL: '',
    });
    const [selectedUserType, setSelectedUserType] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const changePillar = (selectedOption) => {
        if(!selectedOption){
            setActions([]);
            setSelectedPillar(null);
            seSelectedPillarId(null);
            return;
        }
        
        setLevel(null);
        setSelectedPillar(selectedOption.value);
        seSelectedPillarId(selectedOption.module);
        
        // Cache the token and id to avoid closure issues
        const currentToken = token;
        const selectedId = selectedOption.id;
        
        API.get('/get-pillar-action', { 
            headers: { Authorization: 'Bearer ' + currentToken } 
        }).then(response => {
            // Only update actions if we're still on the same pillar
            if (selectedId === selectedOption.id) {
                const filteredActions = response.data.filter((action) => action.parent_id === selectedId);
                setActions(filteredActions);
            }
        }).catch(error => {
            console.error('Error fetching actions:', error);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isOpen || !user) return; // Only fetch when modal is open and we have user data
            
            try {
                const pillarsResponse = await API.get('/sio-pillar', { 
                    headers: { Authorization: 'Bearer ' + token } 
                });
                setPillars(pillarsResponse.data);

                setSelectedUser({
                    value: user.id,
                    label: user["member name"],
                    email: user.username
                });
                setSelectedPosition(user.position);
                setLevel(user.level);
                setPermissionType(user.permission_type);
                
                // Set pillar selection using pillar_name
                if (user.pillar_name) {
                    const userPillar = pillarsResponse.data.find(p => p.name === user.pillar_name);
                    if (userPillar) {
                        setSelectedPillar(userPillar.name);
                        seSelectedPillarId(userPillar.module);
                        
                        // Fetch actions for the selected pillar
                        const actionsResponse = await API.get('/get-pillar-action', { 
                            headers: { Authorization: 'Bearer ' + token } 
                        });
                        const filteredActions = actionsResponse.data.filter(
                            (action) => action.parent_id === userPillar.id
                        );
                        setActions(filteredActions);
                        
                        // Set action selection using action_name
                        if (user.action_name) {
                            const userAction = filteredActions.find(action => action.name === user.action_name);
                            if (userAction) {
                                setSelectedAction(userAction);
                            }
                        }
                    }
                }

                setActionOfficeUser(user.action_office_user);
                setEltUser(user.elt_user);
                setCommsUser(user.comms_user);
                setSelectedDashboardvisible(user.dashboard);
                setSelecteddasboardposition(user.dashboard_position);
                setUserDetails({
                    jobTitle: user.job_title || '',
                    webexName: user.webex_name || '',
                    webexURL: user.webex_url || '',
                });
                setSelectedUserType(user.user_type);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [user, isOpen]); // Only depend on user and isOpen

    const permissionTypes = ['editor', 'contributor', 'viewer'];
    const permissionTypesOptions = permissionTypes.map((type) => ({
        value: type,
        label: type.charAt(0).toUpperCase() + type.slice(1),
    }));

    const levelTypes = ['level1', 'level2', 'level3'];
    const levelTypesOptions = levelTypes.map((level) => ({
        value: level,
        label: level.charAt(0).toUpperCase() + level.slice(1),
    }));

    const positionTypes = ['pillar_owner', 'action_owner', 'pillar_contributor', 'action_contributor', 'action_team_member', 'pillar_team_member', 'elt_sponsor', 'Viewer'];
    const positionTypesOptions = positionTypes.map((position) => ({
        value: position,
        label: position === 'elt_sponsor'
            ? 'ELT Sponsor'
            : position.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    }));

    const pillarOptions = pillars.map((pillar) => ({
        value: pillar.name,
        label: pillar.name,
        id: pillar.id,
        module: pillar.module
    }));

    const actionOptions = actions.map((action) => ({
        value: action.name,
        label: action.name,
        id: action.id,
        module: action.module
    }));

    const userTypes = [
        { value: 'leaders', label: 'Leaders' },
        { value: 'elt_sponsors', label: 'ELT Sponsors' },
        { value: 'members', label: 'Members' }
    ];

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const handleEdit = () => {
        const formData = new FormData();
        formData.append('member_id', id);
        if(selectedPosition !== null){
            formData.append('position', selectedPosition);
        }
        if (level !== null) {
            formData.append('level', level);
        }
        console.log("pillar", selectedPillarId);
        // Use pillar and action names directly
        if(selectedPillar !== null){   
            if(selectedAction !== null){
                // If both pillar and action are selected, use action name
                formData.append('module', selectedAction?.module);
            } else {
                // If only pillar is selected, use pillar name
                formData.append('module', selectedPillarId);
            }
        }
        
        formData.append('action_office_user', actionOfficeUser);
        formData.append('elt_user', eltUser);
        formData.append('comms_user', commsUser);
        formData.append('permission_type', permissionType);
        formData.append('dashboard', selectedDashboardvisible);
        if(selecteddasboardposition !== null){
            formData.append('dashboard_position', selecteddasboardposition);
        }
        formData.append('job_title', userDetails.jobTitle);
        formData.append('webex_name', userDetails.webexName);
        formData.append('webex_url', userDetails.webexURL);
        // formData.append('user_type', selectedUserType);

        API.put('/team-member', formData, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                toast({
                    title: 'Success',
                    description: 'User updated successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                closeModal();
                if (getUpdateUser) getUpdateUser();
            })
            .catch((error) => {
                toast({
                    title: 'Error',
                    description: 'Failed to update user',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                console.error('Error updating user:', error);
            });
    };

    return (
        <>
        <Tooltip label="Edit user" aria-label="Edit user">
            <IconButton
                icon={<EditIcon />}
                sx={{color: '#fff',backgroundColor: '#00A0DA'}}
                aria-label="Edit user"
                size="sm"
                onClick={openModal}
                mr={2}
                />
                </Tooltip>
            <Modal isOpen={isOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent maxWidth="800px">
                    <ModalHeader>Edit User Permissions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                            <FormControl mb="4">
                                <FormLabel>Select a user</FormLabel>
                                <Select
                                    value={selectedUser}
                                    options={[selectedUser]}
                                    isDisabled={true}
                                    styles={customStyles}
                                    formatOptionLabel={({ label, email }) => (
                                        <div>
                                            <div>{label}</div>
                                            <div style={{ fontSize: '0.875rem', color: '#718096' }}>{email}</div>
                                        </div>
                                    )}
                                />
                            </FormControl>

                            <FormControl mb="4">
                                <FormLabel>Access Permissions</FormLabel>
                                <Select
                                    options={positionTypesOptions}
                                    placeholder="Select a Permissions"
                                    isClearable
                                    styles={customStyles}
                                    value={positionTypesOptions.find(opt => opt.value === selectedPosition)}
                                    onChange={(option) => setSelectedPosition(option ? option.value : null)}
                                    isDisabled={actionOfficeUser || eltUser || commsUser}
                                />
                            </FormControl>

                            <FormControl mb="4">
                                <FormLabel>Display Position</FormLabel>
                                <Select
                                    options={userTypes}
                                    placeholder="Select a position"
                                    isClearable
                                    styles={customStyles}
                                    value={userTypes.find(opt => opt.value === selecteddasboardposition)}
                                    onChange={(option) => setSelecteddasboardposition(option ? option.value : null)}
                                />
                            </FormControl>


                           

                           

                            <FormControl mb="4">
                                <FormLabel>Select SIIO</FormLabel>
                                <Select
                                    options={pillarOptions}
                                    placeholder="Select SIIO"
                                    isClearable
                                    styles={customStyles}
                                    value={pillarOptions.find(opt => opt.value === selectedPillar)}
                                    onChange={changePillar}
                                    isDisabled={actionOfficeUser || eltUser || commsUser || level !== null}
                                />
                            </FormControl>

                            <FormControl mb="4">
                                <FormLabel>Select action</FormLabel>
                                <Select
                                    options={actionOptions}
                                    placeholder="Select an action"
                                    isClearable
                                    styles={customStyles}
                                    value={actionOptions.find(opt => opt.value === selectedAction?.name)}
                                    onChange={(option) => setSelectedAction(option ? {
                                        name: option.value,
                                        id: option.id,
                                        module: option.module
                                    } : null)}
                                    isDisabled={!selectedPillar || level !== null}
                                />
                            </FormControl>
                            <FormControl mb="4">
                                <FormLabel>Level</FormLabel>
                                <Select
                                    options={levelTypesOptions}
                                    placeholder="Select a level"
                                    isClearable
                                    styles={customStyles}
                                    value={levelTypesOptions.find(opt => opt.value === level)}
                                    onChange={(option) => setLevel(option ? option.value : null)}
                                    isDisabled={actionOfficeUser || eltUser || commsUser || selectedPillar !== null}
                                />
                            </FormControl>
                            <FormControl mb="4">
                                <FormLabel>Webex name</FormLabel>
                                <Input
                                    placeholder="Provide Webex name"
                                    value={userDetails.webexName}
                                    onChange={(e) => setUserDetails(prev => ({ ...prev, webexName: e.target.value }))}
                                />
                            </FormControl>

                            <FormControl mb="4">
                                <FormLabel>Webex URL</FormLabel>
                                <Input
                                    placeholder="Provide Webex URL"
                                    value={userDetails.webexURL}
                                    onChange={(e) => setUserDetails(prev => ({ ...prev, webexURL: e.target.value }))}
                                />
                            </FormControl>
                            <FormControl mb="0" display="flex" alignItems="center">
                                <FormLabel mb="0" htmlFor="dashboard-user-switch">Dashboard Visible?</FormLabel>
                                <Switch
                                    id="dashboard-user-switch"
                                    isChecked={selectedDashboardvisible}
                                    onChange={(e) => setSelectedDashboardvisible(e.target.checked)}
                                />
                            </FormControl>
                          

                            <FormControl mb="4" display="flex" alignItems="center">
                                <FormLabel mb="0" htmlFor="elt-user-switch">ELT User?</FormLabel>
                                <Switch
                                    id="elt-user-switch"
                                    isChecked={eltUser}
                                    onChange={(e) => {
                                        setEltUser(e.target.checked);
                                        if (e.target.checked) {
                                            setActionOfficeUser(false);
                                            setCommsUser(false);
                                        }
                                    }}
                                    // isDisabled={selectedPosition !== null}
                                />
                            </FormControl>


                            <FormControl mb="4" display="flex" alignItems="center">
                                <FormLabel mb="0" htmlFor="comms-user-switch">Comms User?</FormLabel>
                                <Switch
                                    id="comms-user-switch"
                                    isChecked={commsUser}
                                    onChange={(e) => {
                                        setCommsUser(e.target.checked);
                                        if (e.target.checked) {
                                            setActionOfficeUser(false);
                                            setEltUser(false);
                                        }
                                    }}
                                    // isDisabled={selectedPosition !== null}
                                />
                            </FormControl>
                            <FormControl mb="4" display="flex" alignItems="center">
                                <FormLabel mb="0" htmlFor="action-office-switch">Action Office User?</FormLabel>
                                <Switch
                                    id="action-office-switch"
                                    isChecked={actionOfficeUser}
                                    onChange={(e) => {
                                        setActionOfficeUser(e.target.checked);
                                        if (e.target.checked) {
                                            setEltUser(false);
                                            setCommsUser(false);
                                        }
                                    }}
                                    // isDisabled={selectedPosition !== null}
                                />
                            </FormControl>
                           

                        </Grid>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                        <Button colorScheme="blue" ml={3} onClick={handleEdit} isLoading={isLoading}>
                            Save Changes
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditUser;

// import React, { useEffect, useState } from 'react';
// import { withRouter, useNavigate  } from 'react-router-dom';
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
// import AppRoutes from './AppRoutes'
// import './App.css';
// import './App.scss';
// import Layout from './Layout/Layout';
// import { ChakraProvider } from '@chakra-ui/react'
// import Login from './Pages/Login/Login';
// import BeforeLogin from './Layout/BeforeLogin';
// import AppRoutesBeforeLogin from './AppRoutesBeforeLogin';
// import { EditProvider } from './Context/BreadcrumbsContext';

// function App() {
//   const token = localStorage.getItem('accessToken');
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!token) {
//       navigate('/'); // Redirect to login page
//     }
//   }, [token, navigate]);

//   return (
//     <div className="App">
//       <EditProvider>
//       <ChakraProvider>
//         {
//           !token ?
//           <BeforeLogin>
//             <AppRoutesBeforeLogin />
//           </BeforeLogin>:
//           <Layout>
//             <AppRoutes />
//           </Layout>
//         }
//       </ChakraProvider>
//       </EditProvider>
//     </div>
//   );
// }

// export default App;




import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AppRoutesBeforeLogin from "./AppRoutesBeforeLogin";
import Layout from "./Layout/Layout";
import SettingsPanel from "./Components/Shared/Panel/SettingPanel/SettingsPanel";
import Login from "./Pages/Login/Login";
import BeforeLogin from "./Layout/BeforeLogin";
import { EditProvider } from "./Context/BreadcrumbsContext";
import "./App.css";
import "./App.scss";

// Okta config with PKCE enabled
export const oktaAuthC = new OktaAuth({
  issuer: process.env.REACT_APP_SSO_ISSUER,
  // clientId: process.env.REACT_APP_SSO_CLIENT ,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID ,
  redirectUri: process.env.REACT_APP_REDIRECTED_URI,
  responseType: "code",
  scopes: ["openid", "profile", "email"],
  pkce: true,
});

function App() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
// console.log("process",process.env.REACT_APP_SSO_CLIENT)
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    // If the originalUri is the login callback, redirect to the main app
    if (originalUri === "/") {
      navigate("/dashboard"); // Navigate to your main app route after login
    } else {
      navigate(originalUri || "/");
    }
  };

  return (
    <div className="App">
      <EditProvider>
        <ChakraProvider>
          <Security oktaAuth={oktaAuthC} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
              <Route path="/login/callback" element={<LoginCallback />} />
              <Route path="/login" element={<Login />} />
              {!token ? (
                <Route
                  path="*"
                  element={
                    <BeforeLogin>
                      <AppRoutesBeforeLogin />
                    </BeforeLogin>
                  }
                />
              ) : (
                <Route
                  path="*"
                  element={
                    <Layout>
                      <AppRoutes />
                      <SettingsPanel />
                    </Layout>
                  }
                />
              )}
            </Routes>
          </Security>
        </ChakraProvider>
      </EditProvider>
    </div>
  );
}

export default App;
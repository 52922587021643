import React, { useState } from 'react';
import Styles from './approve.module.css';
import API from '../../../../../Services/API';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { IconButton, Box, useToast, Text, Img } from '@chakra-ui/react';
import { Heading, FormControl, Switch, FormLabel, Tooltip } from '@chakra-ui/react';
import { ViewIcon, CloseIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import Indicators from './Indicators/Indicators';
import FooterImg from '../../../../../../assets/images/cisco-white.png';

const View = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [data, setData] = useState([])
    const user = JSON.parse(window.localStorage.getItem('user'));
    const [edit, setEdit] = useState(false);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [paneltitle, setPaneltitle] = useState(props.title);
    const toast = useToast();

    const togglePane = () => {
        setIsPaneOpen(prevState => !prevState);
        API.get(`/get-qbr?fiscal_year=${props.fiscalyear}&action_id=${props.action}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setData(response.data)
        }).catch(error=>{
        })
    }

    const handleClosePane = () => {
        setEdit(false); // Reset edit state to false
        togglePane(); // This will close the pane
        handleApiCallOnClose(); // Call the API after closing the pane
    };

    const handleApiCallOnClose = async () => {
        
    };

    return (
        <>
            <Tooltip label="View" aria-label="View">
                <IconButton
                    ml={'5px'}
                    variant="solid"
                    aria-label="View"
                    icon={<ViewIcon />}
                    className={Styles.edit}
                    onClick={togglePane}
                />
            </Tooltip>
            <SlidingPane
                closeIcon={<span className='closeTooltip'> <CloseIcon /> </span>}
                isOpen={isPaneOpen}
                onRequestClose={handleClosePane}
                from="right"
                
                title={props.paneltitle}
            >
                <Box id='slideTwo'>
                    <Box className={Styles.container} mt={'10px'}>
                        <Box width={'100%'} maxWidth={'1080px'} m={'0 auto'}>
                            <Heading mt={'20px'} fontWeight={'normal'} fontSize={'24px'}>
                                {props.paneltitle && props.paneltitle.split(/[-–]/)[0] || ""} : Key Performance Indicators
                                <Box float={'right'}>
                                    <Text as={'span'} fontSize={'14px'}> Action execution status :  </Text> 
                                        {props.actionExecutionStatus && <Text fontSize={'14px'} as={'span'} backgroundColor={props.actionExecutionStatus === 'Delayed' ? 'red' : 'green' } p={'5px 10px'}> {props.actionExecutionStatus} 
                                    </Text>}
                                </Box>
                            </Heading>
                            <Box minH={'400px'}>
                                <Indicators actionId={props.action} />
                            </Box>
                            <Box float={'right'} mt={'-12px'} position={'relative'} zIndex={'9'}>
                            {/* {
                                status === "Ready to Submit" ? <Button onClick={submitForApproval}> Submit for Approval </Button> : null
                            } */}
                            </Box>
                            <Text fontSize="sm" position="relative" top="5px" left="0px" fontWeight="light" color="#ffffff">
                                <Img src={FooterImg} maxW={'40px'} display={'inline'} mr={'10px'} /> 
                                <Text fontSize={'11px'} as={'span'}> © 2025 Cisco and/or its affiliates. All rights reserved. Cisco confidential. </Text>
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </SlidingPane>
        </>
    )
}

export default View
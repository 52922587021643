import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import $ from 'jquery';
import Styles from '../aspirations.module.css';
import { useParams } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Switch,
    Text,
    useDisclosure,
    IconButton,
    Tooltip,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import API from '../../../../../../Services/API';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Widgets/ErrorWidget/ErrorWidget'

const EditAspiration = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const { metricId } = useParams();
    const [aspirations, setAspirations] = useState();
    const [fiscalYearList, setFiscalYearList] = useState();
    const [fiscalQtrList, setFiscalQtrList] = useState();
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [getQtrList, setGetQtrList] = useState([]);
    const [allow, setAllow] = useState(false);
    const [validationError, setValidationError] = useState({});
    const [initialState, setInitialState] = useState({});
    const [user, setUser] = useState({});
    const [message, setMessage] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        API.get('/fiscal-year-detail', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                const yearList = response.data[0].fiscalyear.map((item) => ({ value: item.id, label: item.name }));
                const quarterList = response.data[1].fiscalquarter.map((item) => ({ value: item.id, label: item.name }));
                setFiscalYearList(yearList);
                setFiscalQtrList(quarterList);

                const defaultYear = yearList.find((year) => year.label === props.fiscalYear);
                const defaultQtr = quarterList.find((qtr) => qtr.label === props.fiscalQtr);
                setInitialState({
                    name: props.title || '',
                    value: props.value || '',
                    fiscalYear: defaultYear || null,
                    fiscalQuarter: defaultQtr || null,
                    allowExport: props.allowExport || false,
                });

                setSelectedFiscalYear(defaultYear || null);
                setSelectedFiscalQtr(defaultQtr || null);
            })
            .catch((error) => console.error('Failed to fetch fiscal data:', error))
            .finally(() => setIsLoading(false));

        API.get(`/get-specific-modules?metric_id=${metricId}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
            .then((response) => {
                setUser(response.data);
            })
            .catch((error) => setIsLoading(false));
    }, [token, props.fiscalYear, props.fiscalQtr, props.title, props.value]);

    useEffect(() => {
        if (!selectedFiscalYear || !fiscalQtrList?.length) {
            setGetQtrList([]);
            return;
        }

        const SelectedOptionNew = `FY${selectedFiscalYear.label.slice(-2)}`;
        const relatedQuarters = fiscalQtrList
            .filter((item) => item.label && item.label.includes(SelectedOptionNew))
            .sort((a, b) => {
                const quarterA = parseInt(a.label.split(' ')[1]?.replace('Q', ''), 10) || 0;
                const quarterB = parseInt(b.label.split(' ')[1]?.replace('Q', ''), 10) || 0;
                return quarterB - quarterA;
            });

        setGetQtrList(relatedQuarters);
    }, [selectedFiscalYear, fiscalQtrList]);

    const getChangedFields = () => {
        const currentState = {
            name: $("#aspiration_name").val(),
            value: parseInt($("#aspiration_value").val(), 10) || '',
            fiscalYear: selectedFiscalYear?.value || null,
            fiscalQuarter: selectedFiscalQtr?.value || null,
            allowExport: allow,
        };
    
        const initialStateNormalized = {
            name: initialState.name,
            value: parseInt(initialState.value, 10) || '',
            fiscalYear: initialState.fiscalYear?.value || null,
            fiscalQuarter: initialState.fiscalQuarter?.value || null,
            allowExport: initialState.allowExport,
        };
    
        return Object.entries(currentState).reduce((acc, [key, value]) => {
            if (initialStateNormalized[key] !== value) {
                acc[key] = {
                    old: initialStateNormalized[key],
                    new: value,
                };
            }
            return acc;
        }, {});
    };

    const validateForm = () => {
        let valid = true;
        const errors = {
            name: '',
            value: '',
            targetfiscalyear: '',
            targetfiscalquarter: '',
        };

        if (!$("#aspiration_name").val()) {
            errors.name = 'Name is required';
            valid = false;
        }

        if (!$("#aspiration_value").val()) {
            errors.value = 'Value is required';
            valid = false;
        }

        if (!selectedFiscalYear) {
            errors.targetfiscalyear = 'Target Fiscal Year required';
            valid = false;
        }

        setValidationError(errors);
        return valid;
    };

    const updateAspiration = () => {
        setMessage(null)
        const changes = getChangedFields();
        if (Object.keys(changes).length === 0) {
            setMessage("No changes are made");
            return;
        }

        let changeList = "Changes made:\n";
        Object.entries(changes).forEach(([key, { old, new: newValue }]) => {
            changeList += `${key}: "${old}" → "${newValue}"\n`;
        });

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        const body = {
            id: props.id,
            name: $("#aspiration_name").val(),
            value: parseInt($("#aspiration_value").val()),
            targetfiscalyear: parseInt(selectedFiscalYear?.value),
            targetfiscalquarter: parseInt(selectedFiscalQtr?.value),
            allow_export_externally: allow,
            approval_status: user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'  ? "approved" : "pending",
            position: user.superuser ? 'superuser'
                    : user.action_office_user ? 'action_office_user' 
                    : user.comms_user ? 'comms_user' 
                    : user.elt_user ? 'elt_user' 
                    : user.position === 'pillar_owner' ? 'pillar_owner' 
                    : user.position === 'pillar_contributor' ? 'pillar_contributor' 
                    : user.position === 'pillar_member' ? 'pillar_member' 
                    : user.position === 'action_owner' ? 'action_owner' 
                    : user.position === 'action_contributor' ? 'action_contributor' 
                    : user.position === 'action_member' ? 'action_member' : '',
            position_of_user: (user.position === 'pillar_owner' || user.position === 'action_owner') ? 'owner'
                            : (user.position === 'action_contributor' || user.position === 'pillar_contributor') ? 'contributor' : ''
        };

        API.put(`/metric-target`, body, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
        .then(() => {
            if(user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'){
                setMessage('Thank you! Please refresh the screen to view the updated data.');
            }else{
                setMessage('Thank you! Your data has been successfully submitted for approval.');
            }
            API.get(`/metric-target?metric_id=${metricId}`)
                .then((response) => {
                    onClose();
                    props.getUpdatedAspiration(response.data);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        })
            .catch(() => setIsLoading(false));
    };

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption);
        setSelectedFiscalQtr(null);
    };

    const handleFiscalQtrChange = (selectedOption) => {
        setSelectedFiscalQtr(selectedOption);
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} /> }
            <Tooltip label="Edit Aspiration" aria-label="Edit Aspiration">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    icon={<EditIcon />}
                    className={Styles.edit}
                    onClick={onOpen}
                    minW={'25px'}
                    minH={'25px'}
                    width={'25px'}
                    height={'25px'}
                />
            </Tooltip>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Aspiration</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Title</FormLabel>
                            <Input id="aspiration_name" defaultValue={props.title} />
                            <Text color="red">{validationError.name}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Value</FormLabel>
                            <Input id="aspiration_value" defaultValue={props.value} />
                            <Text color="red">{validationError.value}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Target Fiscal Year</FormLabel>
                            <Select
                                options={fiscalYearList}
                                value={selectedFiscalYear}
                                onChange={handleFiscalYearChange}
                            />
                            <Text color="red">{validationError.targetfiscalyear}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Target Fiscal Quarter</FormLabel>
                            <Select
                                options={getQtrList}
                                value={selectedFiscalQtr}
                                onChange={handleFiscalQtrChange}
                                isDisabled={!selectedFiscalYear}
                            />
                        </FormControl>
                        <FormControl mt={'10px'}>
                            <FormLabel fontWeight={'bold'}>Share Data Externally <Switch onChange={(e) => setAllow(e.target.checked)} ml={'10px'} mt={'3px'} /></FormLabel>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter background={'transparent'}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={updateAspiration} ml={'10px'} colorScheme='blue'>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditAspiration;

import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery';
import API from '../../../../../Services/API';
import Styles from './qtrinfo.module.css';
import {
  Text,
  Heading,
  Input,
  Stack,
  Button,
  Spinner,
  Image,
  Code,
  useToast,
} from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const SelectedYearInfo = (props) => {
  const token = window.localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const editor = useRef(null);
  const toast = useToast();
  const [isMessage, setIsMessage] = useState(null);

  // Sync state with selectedYearInfo
  useEffect(() => {
    if (props.selectedYearInfo) {
      setName(props.selectedYearInfo.name || '');
      setDescription(props.selectedYearInfo.description || '');
      setNote(props.selectedYearInfo.note || '');
    }
  }, [props.selectedYearInfo]);

  const bannerUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const updateFiscalYearInfo = () => {
    setIsMessage(null)
    // Check for changes
    const originalName = props.selectedYearInfo?.name || '';
    const originalDescription = props.selectedYearInfo?.description || '';
    const originalNote = props.selectedYearInfo?.note || '';
    const originalBanner = props.selectedYearInfo?.banner || '';

    if (
      name === originalName &&
      description === originalDescription &&
      note === originalNote &&
      !file
    ) {
        setIsMessage('No changes are made.');
        return;
    }

    // Perform API update
    setIsLoading(true);
    const formData = new FormData();
    formData.append('id', props.selectedYearInfo?.id);
    if (file) formData.append('banner', file);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('note', note);

    API.put(`/fiscal-year/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        API.get(`/fiscal-year/?id=${props.selectedYearInfo?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            props.getUpdated(response.data.results?.[0]);
            setIsMessage('Fiscal year updated successfully.');
          })
          .catch(() => setIsLoading(false));
      })
      .catch(() => {
        setIsMessage('Error updating fiscal year');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {
        isMessage && (
          <ErrorWidget message={isMessage} />
        )
      }
      {props.editable ? (
        <Stack spacing={3} mb="30px">
          <Input
            placeholder="Please add fiscal year info here ..."
            size="md"
            backgroundColor="#ffffff"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input type="file" onChange={bannerUpload} padding="4px" />
          {props.selectedYearInfo?.banner && (
            <Image
              src={props.selectedYearInfo.banner}
              alt={props.selectedYearInfo.name || 'Banner'}
            />
          )}
          <JoditEditor
            ref={editor}
            config={{
              readonly: false,
              tabIndex:"0"
          }}
            value={description}
            tabIndex={1}
            onChange={(content) => setDescription(content)}
          />
          <Input
            placeholder="Add additional note or information about the fiscal year"
            size="md"
            backgroundColor="#ffffff"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Button className="primaryButtonNew" w={100} onClick={updateFiscalYearInfo}>
            Save
          </Button>
        </Stack>
      ) : (
        <Stack spacing={6} mb="30px">
          <Heading as="h2" size="lg">
            {props.selectedYearInfo?.name}
          </Heading>
          {props.selectedYearInfo?.banner && (
            <Image
              src={props.selectedYearInfo.banner}
              alt={props.selectedYearInfo.name || 'Banner'}
            />
          )}
          {props.selectedYearInfo?.description ? parse(props.selectedYearInfo.description) : ''}
          <Code>{props.selectedYearInfo?.note}</Code>
        </Stack>
      )}
    </>
  );
};

export default SelectedYearInfo;

import React, { useState, useEffect, useMemo } from 'react';
import { Button, FormErrorMessage, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Grid, Switch } from '@chakra-ui/react';
import Select from 'react-select';
import API from '../../../../../Services/API';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: '#CBD5E0',
    borderRadius: '0.375rem',
    padding: '2px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#3182CE',
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 5,
  }),
};

const AddUser = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const token = window.localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [permissionType, setPermissionType] = useState(null);
  const [level, setLevel] = useState(null);
  const [pillars, setPillars] = useState([]);
  const [actions, setActions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPillar, setSelectedPillar] = useState(null);
  const [selectedDashboardvisible, setSelectedDashboardvisible] = useState(false);
  const [selecteddasboardposition, setSelecteddasboardposition] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [userDetails, setUserDetails] = useState({
    jobTitle: '',
    webexName: '',
    webexURL: '',
  });
  const [actionOfficeUser, setActionOfficeUser] = useState(false);
  const [eltUser, setEltUser] = useState(false);
  const [commsUser, setCommsUser] = useState(false);
  const [message, setMessage] = useState(null);

  const [selectedPillarId, seSelectedPillarId] = useState(null);

  const permissionTypes = ['editor', 'contributor', 'viewer'];
  const levelTypes = ['level1', 'level2', 'level3'];
  const positionTypes = ['pillar_owner', 'action_owner', 'pillar_contributor', 'action_contributor', 'action_team_member', 'pillar_team_member', 'elt_sponsor', 'Viewer'];

  const permissionTypesOptions = permissionTypes.map((type) => ({
    value: type,
    label: type.charAt(0).toUpperCase() + type.slice(1),
  }));

  const levelTypesOptions = levelTypes.map((level) => ({
    value: level,
    label: level.charAt(0).toUpperCase() + level.slice(1),
  }));

  const positionTypesOptions = positionTypes.map((position) => ({
    value: position,
    label: position === 'elt_sponsor'
      ? 'ELT Sponsor'
      : position
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
  }));

  const pillarOptions = pillars.map((pillar) => ({
    value: pillar.module,
    label: pillar.name,
    id: pillar.id
  }));
  
  const actionOptions = actions.map((action) => ({
    value: action.module,
    label: action.name,
    id: action.parent_id
  }));

const usertypess=[{value: 'leaders', label: 'Leaders'},{value: 'elt_sponsors', label: 'ELT Sponsors'},{value: 'members', label: 'Members'}]

  const userTypeOptions = usertypess.map((action) => ({
    value: action.value,
    label: action.label,
    // id: action.parent_id
  }));


  const [formErrors, setFormErrors] = useState({});
  const changePillar = (selectedOption)=>{
    if(!selectedOption){
      setActions([])
      setSelectedPillar(null)
    }else{
      setLevel(null)
      setSelectedPillar(selectedOption ? selectedOption.value : null)
      seSelectedPillarId(selectedOption ? selectedOption.id : null)
      API.get('/get-pillar-action', { 
        headers: { Authorization: 'Bearer ' + token } 
      }).then(response=>{
        setActions(response.data.filter((action) => action.parent_id === selectedOption.id));
      }).catch(error=>{

      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersResponse, pillarsResponse, actionsResponse] = await Promise.all([
          API.get('/list-user', { headers: { Authorization: 'Bearer ' + token } }),
          API.get('/sio-pillar', { headers: { Authorization: 'Bearer ' + token } }),
        ]);
        setUsers(usersResponse.data);
        setPillars(pillarsResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const formattedOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email,
      })),
    [users]
  );

  const openModal = () => {
    setIsOpen(true);
    resetForm();
  };
  const closeModal = () => {
    setIsOpen(false);
    resetForm();
  };
  const resetForm = () => {
    setSelectedUser(null);
    setPermissionType(null);
    setUserDetails({
      jobTitle: '',
      webexName: '',
      webexURL: '',
    });
    setLevel(null);
    setSelectedPillar(null);
    setSelectedAction(null);
    setSelectedPosition(null);
    setActionOfficeUser(false);
    setEltUser(false);
    setCommsUser(false);
    setFormErrors({});
    setMessage(null);
  };
console.log("selecteddasboardposition",selectedDashboardvisible)
  const handleSave = () => {
    setMessage(null);
    const errors = {};
    let isValid = true;
  
    // Validation checks
    if (!selectedUser) {
      errors.user = 'Please select the user';
      isValid = false;
    }
    
    // Position is required only if Level is not selected
    if (!actionOfficeUser && !eltUser && !commsUser && !selectedPosition && !level) {
      errors.position = 'Please select the position or any user type';
      isValid = false;
    }
  
    setFormErrors(errors);
  
    if (isValid) {
      // Create FormData object
      const formData = new FormData();
  
      formData.append('user', selectedUser.value);
      formData.append('job_title', userDetails.jobTitle);
      formData.append('description', userDetails.description || ''); // Optional field
      formData.append('webex_name', userDetails.webexName);
      formData.append('webex_url', userDetails.webexURL);
      if (permissionType !== null) {
        formData.append('permission_type', permissionType);
      } else {
        formData.append('permission_type', 'viewer');
      }
  
      if (level !== null) {
        formData.append('level', level);
      }
      if (selectedPosition !== null) {
        formData.append('position', selectedPosition.value);
      }
      if (selectedPillar !== null) {
        formData.append('module', selectedPillar);
      }
      formData.append('action_office_user', actionOfficeUser);
      formData.append('elt_user', eltUser);
      formData.append('comms_user', commsUser);
      formData.append('dashboard', selectedDashboardvisible);
      if (selecteddasboardposition !== null) {
        formData.append('dashboard_position', selecteddasboardposition);
      }
      
      setIsLoading(true);
  
      // Send the form data with the POST request
      API.post('/team-member', formData, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data', // Explicitly set the content type to multipart/form-data
        },
      })
        .then((response) => {
          setMessage('Added user successfully');
          props.getUpdateUser();
          closeModal();
        })
        .catch((error) => {
          setMessage(error.response.data.msg);
          console.error('Error adding user:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const changePosition = (selectedOption) =>{
    if(!selectedOption){
      setSelectedPosition(null)
      setPermissionType(null)
    }else{
      setSelectedPosition(selectedOption)
      if(selectedOption.value === 'pillar_owner' || selectedOption.value === 'action_owner'){
        setPermissionType('editor')
      }else if(selectedOption.value === 'pillar_contributor' || selectedOption.value === 'action_contributor'){
        setPermissionType('contributor')
      }else {
        setPermissionType('viewer')
      }
    }
    
  }

  const handleChange = (event)=>{
    
    if(event.target.id === "action-office-switch"){
      setActionOfficeUser(event.target.checked)
      setEltUser(false)
      setCommsUser(false)

    }else if(event.target.id === "elt-user-switch"){
      setActionOfficeUser(false)
      setEltUser(event.target.checked)
      setCommsUser(false)
    }else if(event.target.id === "comms-user-switch"){
      setActionOfficeUser(false)
      setEltUser(false)
      setCommsUser(event.target.checked)
    }
    if(event.target.id === "dashboard-user-switch"){
     
      setSelectedDashboardvisible(event.target.checked)
    }
  }
  

  return (
    <>
      {
        isLoading && isLoading ? <LoaderSpinner /> : null
      }
      {
        message && message ? <ErrorWidget message={message} /> : null
      }
      <Box mb="10px" display="flex" justifyContent="flex-end">
        <Button className="primaryButtonNew" fontSize="13px" onClick={openModal}>
          Add User
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent maxWidth="800px">
          <ModalHeader>Assign Permissions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              <FormControl isInvalid={formErrors.user} mb="4">
                <FormLabel>Select a user</FormLabel>
                <Select
                  options={formattedOptions}
                  placeholder="Select a user"
                  isClearable
                  styles={customStyles}
                  value={selectedUser}
                  onChange={setSelectedUser}
                />
                {formErrors.user && <FormErrorMessage>{formErrors.user}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={formErrors.position} mb="4">
                <FormLabel>Access Permissions</FormLabel>
                <Select
                  options={positionTypesOptions}
                  placeholder="Select a  Permissions"
                  isClearable
                  styles={customStyles}
                  onChange={changePosition}
                  isDisabled={actionOfficeUser || eltUser || commsUser}
                />
                {formErrors.position && <FormErrorMessage>{formErrors.position}</FormErrorMessage>}
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Display Position </FormLabel>
                <Select
                  options={userTypeOptions}
                  placeholder="Select a position"
                  isClearable
                  // isDisabled={!selectedPillar || level !== null ? true : false}
                  styles={customStyles}
                  onChange={(selectedOption) => setSelecteddasboardposition(selectedOption ? selectedOption.value : null)}
                />
              </FormControl>
              {/* <FormControl mb="4">
                <FormLabel>Job title</FormLabel>
                <Input
                  placeholder="Provide job title"
                  value={userDetails.jobTitle}
                  onChange={(e) => setUserDetails({ ...userDetails, jobTitle: e.target.value })}
                />
              </FormControl> */}

             

            
              
              <FormControl isInvalid={formErrors.pillar} mb="4">
                <FormLabel>Select SIIO</FormLabel>
                <Select
                  options={pillarOptions}
                  placeholder="Select SIIO"
                  isClearable
                  styles={customStyles}
                  onChange={changePillar}
                  isDisabled={actionOfficeUser || eltUser || commsUser || level !== null ? true : false}
                />
                {formErrors.pillar && <FormErrorMessage>{formErrors.pillar}</FormErrorMessage>}
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Select action</FormLabel>
                <Select
                  options={actionOptions}
                  placeholder="Select an action"
                  isClearable
                  isDisabled={!selectedPillar || level !== null ? true : false}
                  styles={customStyles}
                  onChange={(selectedOption) => setSelectedPillar(selectedOption ? selectedOption.value : null)}
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Level</FormLabel>
                <Select
                  options={levelTypesOptions}
                  placeholder="Select a level"
                  isClearable
                  styles={customStyles}
                  isDisabled={actionOfficeUser || eltUser || commsUser || selectedPillar !== null ? true : false}
                  onChange={(selectedOption) => setLevel(selectedOption ? selectedOption.value : null)}
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Webex name</FormLabel>
                <Input
                  placeholder="Provide Webex name"
                  value={userDetails.webexName}
                  onChange={(e) => setUserDetails({ ...userDetails, webexName: e.target.value })}
                />
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Webex URL</FormLabel>
                <Input
                  placeholder="Provide Webex URL"
                  value={userDetails.webexURL}
                  onChange={(e) => setUserDetails({ ...userDetails, webexURL: e.target.value })}
                />
              </FormControl>
             
            
              <FormControl mb="0" display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel mb="0" htmlFor="dashboard-user-switch">
                Dashboard Visible?
                </FormLabel>
                <Switch
                  id="dashboard-user-switch"
                  isChecked={selectedDashboardvisible}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mb="4" display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel mb="0" htmlFor="elt-user-switch">
                  ELT user?
                </FormLabel>
                <Switch
                  id="elt-user-switch"
                  isChecked={eltUser}
                  onChange={handleChange}
                  isDisabled={selectedPosition !== null ? true : false}
                />
              </FormControl>

              <FormControl mb="4" display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel mb="0" htmlFor="comms-user-switch">
                  Comms user?
                </FormLabel>
                <Switch
                  id="comms-user-switch"
                  isChecked={commsUser}
                  onChange={handleChange}
                  isDisabled={selectedPosition !== null ? true : false}
                />
              </FormControl>

              <FormControl mb="4" display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel mb="0" htmlFor="action-office-switch">
                  Action office user?
                </FormLabel>
                <Switch
                  id="action-office-switch"
                  isChecked={actionOfficeUser}
                  onChange={handleChange}
                  isDisabled={selectedPosition !== null ? true : false}
                />
              </FormControl>
             
            </Grid>
          </ModalBody>

          <ModalFooter backgroundColor={'transparent'}>
            <Button variant="ghost" onClick={closeModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddUser;

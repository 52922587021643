import React from 'react'
import { useParams } from 'react-router-dom'
import Styles from './actionList.module.css'
import { Link } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons'
import { Heading, Stack, Card, CardBody, Box, Image, Text, Input, Textarea, Button, Badge} from '@chakra-ui/react'
import PlaceholderImg from '../../../../../assets/images/placeholder-1.png'
import DeleteAction from './DeleteAction/DeleteAction';
const ActionsList = (props) => {
    const {id} = useParams();
    const {name} = useParams();
    
    return (
        <>
            <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'  tabIndex={"0"} sx={{
              cursor: "pointer",
              transition: "0.3s",
              "&:focus": {
                outline: "2px solid #00AAE0", // Highlight border on focus
                boxShadow: "0 0 5px rgba(0, 170, 224, 0.7)", // Optional glow effect
              },
            }}
            onKeyDown={(event) => {
              // Only handle Enter key when the card is focused and not the delete icon
              if (event.key === "Enter" && event.target === event.currentTarget) {
                window.location.assign(`/pillar/${id}/${name}/${props.module}/${encodeURIComponent(props.name)}`);
              }
            }}
            >
                {
                    props.editable ? <DeleteAction id={props.id} getUpdated={props.getUpdated} getMessage={props.getMessage} /> : null
                }
                <Link className={props.editable ? Styles.customLinkButtonEdit : Styles.customLinkButton} to={`/pillar/${id}/${name}/${props.module}/${encodeURIComponent(props.name)}`}>
                    {/* <Box className={Styles.imageContainer}>
                        <Image src={props.banner !== null ? props.banner  : PlaceholderImg} alt={props.name} />
                    </Box>
                    
                    <Box className={Styles.titleParent}>
                        <Box as='h5' className={Styles.fontSec}>
                            {props.name}
                        </Box>
                    </Box> */}

                    <Card maxW='sm'>
                          <CardBody p={0}>
                          <Box
                            style={{
                              width: '100%',
                              //paddingBottom: `${(0.5 / aspectRatio) * 100}%`,
                              paddingBottom: '76px',
                              position: 'relative',
                            }}
                          >
                            <Image
                              src={props.banner !== null ? props.banner : PlaceholderImg}
                              alt="Description of the image"
                              borderRadius='lg'
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', // Ensures the image covers the entire container
                              }}
                            />
                          </Box>
                            {/* <Image
                              src={item.cover_image !== null ? item.cover_image : PlaceholderImg}
                              alt='Cover image'
                              borderRadius='lg'
                            /> */}
                            <Stack mt='4' spacing='3'>
                              <Heading size='sm' p={'0 10px'} className={Styles.listHeading}>{props.name}</Heading>
                            </Stack>
                          </CardBody>
                        </Card>
                </Link>
            </Box>
        </>
    )
}

export default ActionsList
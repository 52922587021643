import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Styles from './createmetric.module.css';
import { Link, useParams } from 'react-router-dom';
import { Heading, Card, CardBody, Box, Image, Text, Input, useDisclosure, Textarea, Button, SimpleGrid, Stack, CardFooter} from '@chakra-ui/react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import API from '../../../../../../../Services/API';
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import ErrorWidget from '../../../../../../Widgets/ErrorWidget/ErrorWidget';

const CreateMetric = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const editor = useRef(null);
    const [message, setMessage] = useState(null);
    const [pillarAction, setPillarAction] = useState([]);
    const [isDashboard, setIsDashboard] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [isPillar, setIsPillar] = useState(false);
    const [isQbr, setIsQbr] = useState(false);
    const [isBoard, setIsBoard] = useState(false);
    const [isInterlock, setIsInterlock] = useState(false);
    const [isMap, setIsMap] = useState(false);
    const [allow, setAllow] = useState(false);
    const { id } = useParams();
    const { actionid } = useParams();
    const [strategy, setStrategy] = useState(false);
    const [errorMessage , setErrorMessage] = useState(null);
    const [tagDetails, setTagDetails] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [selectedDataType, setSelectedDataType] = useState(null);
    const [selectedMetricType, setSelectedMetricType] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedAggregate, setSelectedAggregate] = useState(null);
    const [formErrors, setFormErrors] = useState({
        metricName: '',
        dataType: '',
        metricType: '',
        aggregate: '',
    });
    const [user, setUser] = useState({})

    useEffect(() => {
        API.get(`/get-specific-modules?module_id=${actionid}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setUser(response.data);
        }).catch(error => {
            setIsLoading(false);
        });

        API.get(`/get-pillar-action`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setPillarAction(response.data);
        }).catch(error => {
            setIsLoading(false);
        });

        API.get(`/strategy?action_id=${props.actionId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response1) => {
            setStrategies(response1.data);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        });

        API.get(`/tag-detail/`, {
            headers: { 'Authorization': 'Bearer ' + token },
        }).then((response) => {
            setTagDetails(response.data.results);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [props.actionId, state.isPaneOpen]);

    const resetForm = () => {
        // Reset all form fields
        setSelectedTags([]);
        setSelectedDataType(null);
        setSelectedMetricType(null);
        setSelectedAction(null);
        setSelectedAggregate(null);
        setAllow(false);
        document.getElementById('metricname').value = '';
        document.getElementById('metricdescription').value = '';

        // Reset validation errors
        setFormErrors({
            metricName: '',
            dataType: '',
            metricType: '',
            aggregate: '',
        });
    };

    const openSlidingPane = () => {
        setState({ isPaneOpen: true });
    };

    const closeSlidingPane = () => {
        setState({ isPaneOpen: false });
        resetForm();
    };

    const handleSwitchChange = (key) => (event) => {
        setState((prevState) => ({
            ...prevState,
            [key]: event.target.checked,
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!document.getElementById('metricname').value) {
            errors.metricName = 'Metric name is required.';
        }
        if (!selectedDataType) {
            errors.dataType = 'Data type is required.';
        }
        if (!selectedMetricType) {
            errors.metricType = 'Metric type is required.';
        }
        if (!selectedAggregate) {
            errors.aggregate = 'Aggregate is required.';
        }
        return errors;
    };

    const newMetric = () => {
        setMessage(null)
        const errors = validateForm();
        setFormErrors(errors);
        
        if (Object.keys(errors).length > 0) return;

        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", document.getElementById('metricname').value);
        formData.append("description", document.getElementById('metricdescription').value);
        formData.append("datatype", selectedDataType.value);
        formData.append("metrictype", selectedMetricType.value);
        formData.append("aggregate", selectedAggregate);
        formData.append("map", isMap);
        formData.append("action_id", parseInt(props.actionId));
        formData.append("allow_export_externally", allow);
        formData.append("dashboard", true);
        formData.append("elr", isQbr);
        formData.append('metric_tag', JSON.stringify(selectedTags.map((tag) => tag.value)))

        if(strategy){
            formData.append("strategy", strategy.value);
            formData.append("strategy_name", strategy.label);
        }

        if (selectedAction) {
            formData.append("action_id", selectedAction.value);
        }
        

        if(user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'){
            formData.append('approval_status', 'approved');
        }else{
            formData.append('approval_status', 'pending');
        }

        if(user.superuser){
            formData.append('position', 'superuser');
        }else if(user.action_office_user){
            formData.append('position', 'action_office_user');
        }else if(user.comms_user){
            formData.append('position', 'comms_user');
        }else if(user.elt_user){
            formData.append('position', 'elt_user');
        }else if(user.position === 'pillar_owner'){
            formData.append('position', 'pillar_owner');
        }else if(user.position === 'pillar_contributor'){
            formData.append('position', 'pillar_contributor');
        }else if(user.position === 'pillar_member'){
            formData.append('position', 'pillar_member');
        }else if(user.position === 'action_owner'){
            formData.append('position', 'action_owner');
        }else if(user.position === 'action_contributor'){
            formData.append('position', 'action_contributor');
        }else if(user.position === 'action_member'){
            formData.append('position', 'action_member');
        }

        if(user.position === 'pillar_owner' || user.position === 'action_owner'){
            formData.append('position_of_user', 'owner');
        }else if(user.position === 'action_contributor' || user.position === 'pillar_contributor'){
            formData.append('position_of_user', 'contributor');
        }

        

        API.post(`/metric`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            setState({ isPaneOpen: false });
            API.get(`/metric?action_id=${props.actionId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                props.getUpdatedMetrics(response.data);
                setIsLoading(false);
                if(user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'){
                    setMessage('Thank you! Please refresh the screen to view the updated data.');
                }else{
                    setMessage('Thank you! Your data has been successfully submitted for approval.');
                }
            }).catch(error => {
                setIsLoading(false);
            });
        }).catch(error => {
            setIsLoading(false);
            if(error.response.data.msg){
                setErrorMessage(error.response.data && error.response.data.msg);
            }else if(error.response.data.name) {
                setErrorMessage(error.response.data && error.response.data.name[0]);
            } else {
                setErrorMessage('Apologies for the inconvenience, but an error has occurred. If this issue persists, please reach out to our support team at support@purposefy.com.');
            }
        });
    };

    const dataTypes = [
        { value: 'percent', label: 'Percent' },
        { value: 'number', label: 'Number' },
        { value: 'money', label: 'Money' },
    ];

    const metricTypes = [
        { value: 'operational', label: 'Operational' },
        { value: 'financial', label: 'Financial' },
        { value: 'impact', label: 'Impact' },
    ];

    const aggregateTypes = [
        { value: 'sum', label: 'Sum' },
        { value: 'avg', label: 'Avg' },
        { value: 'max', label: 'Max' },
        { value: 'last', label: 'Last' },
    ];

    const actions = pillarAction.filter(item => item.visible).map(item => ({
        value: item.id,
        label: item.name,
    }));

    // const strategiesOptions = strategies.map((strategy) => ({
    //     value: strategy.id,
    //     label: strategy.name,
    // }));

    const strategiesOptions = strategies.map((strategy) => ({
        value: strategy.id,
        label: strategy.name,
    })).sort((a, b) => a.label.localeCompare(b.label));

    const selectStrategy = (event) => {
        setStrategy(event);
    };

    const metricqbr = (event) => {
        setIsQbr(event.target.checked);
    };

    const metricinterlock = (event) => {
        setIsInterlock(event.target.checked);
    };

    const metricmap = (event) => {
        setIsMap(event.target.checked);
    };

    const onHandleChange = (event) => {
        setAllow(event.target.checked);
    };

    const selectAggregate = (event) => {
        setSelectedAggregate(event.value);
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Button leftIcon={<AddIcon />} className='defaultButton' colorScheme='teal' variant='solid' float={'right'} onClick={openSlidingPane}>
                Add Metric
            </Button>
            {message && <ErrorWidget message={message} />}
            <SlidingPane
                className={Styles.slidingPane}
                overlayClassName="some-custom-overlay-class"
                isOpen={state.isPaneOpen}
                title="Create Metric"
                subtitle=""
                width="50%"
                onRequestClose={closeSlidingPane}
                // onRequestClose={() => {
                //     setState({ isPaneOpen: false });
                // }}
            >
                {errorMessage !== null && <Box color={'red'}>{errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()}</Box>}
                <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'}>
                    <Box>
                        <FormControl isInvalid={!!formErrors.metricName}>
                            <FormLabel fontWeight={'bold'}>
                                Name <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Input type='text' id='metricname' placeholder='Please provide metric name' />
                            {formErrors.metricName && <Box color='crimson'>{formErrors.metricName}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.dataType}>
                            <FormLabel fontWeight={'bold'}>
                                Data Type <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select onChange={setSelectedDataType} options={dataTypes} isClearable />
                            {formErrors.dataType && <Box color='crimson'>{formErrors.dataType}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.metricType}>
                            <FormLabel fontWeight={'bold'}>
                                Metric Type <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select onChange={setSelectedMetricType} options={metricTypes} isClearable />
                            {formErrors.metricType && <Box color='crimson'>{formErrors.metricType}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.aggregate}>
                            <FormLabel fontWeight={'bold'}>
                                Aggregate <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select onChange={selectAggregate} options={aggregateTypes} isClearable />
                            {formErrors.aggregate && <Box color='crimson'>{formErrors.aggregate}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Strategy</FormLabel>
                            <Select onChange={selectStrategy} options={strategiesOptions} isClearable />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Tag</FormLabel>
                            <Select isMulti value={selectedTags} onChange={setSelectedTags} options={tagDetails.map((tag) => ({
                                value: tag.id,
                                label: tag.name,
                            }))} isClearable />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricqbr' mb='0' className={Styles.frmLabel}>
                                Executive Leadership Review
                            </FormLabel>
                            <Switch id='metricqbr' mt={'14px'} onChange={metricqbr} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricmap' mb='0' className={Styles.frmLabel}>
                                Map
                            </FormLabel>
                            <Switch id='metricmap' mt={'14px'} onChange={metricmap} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center' mt={'0px'}>
                            <FormLabel htmlFor='share_data_externally' mb='0' fontWeight={'bold'}>
                                Share Data Externally
                            </FormLabel>
                            <Switch id='share_data_externally' mt={'10px'} onChange={onHandleChange} />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <Box>
                    <FormControl>
                        <FormLabel fontWeight={'bold'}> Description </FormLabel>
                        <JoditEditor
                            id={"metricdescription"}
                            ref={editor}
                            config={{
                                readonly: false,
                                tabIndex: "0" // tabIndex of textarea
                            }}
                            tabIndex={"0"} // tabIndex of textarea
                            onChange={newContent => {}}
                        />
                        <Button colorScheme='blue' onClick={newMetric} mt={'20px'} float={'right'}>Add Metric</Button>
                    </FormControl>
                </Box>
            </SlidingPane>
        </>
    );
};

export default CreateMetric;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './Slider.module.css'
import {Heading, Box, Image, Text } from '@chakra-ui/react'
import FooterImg from '../../../../../../../../../../assets/images/cisco-white.png'



const SectionZero = (props) => {
    const [beforeDash, afterDash] = props.title.split(' - ');
    return (
        <>
          <Box id='pdf-content0' h="420px" className={Styles.sectionBg}>
            <Box className={Styles.titleSection}>
              <Heading as="h3">
                <Text className={Styles.beforeDash}>{beforeDash}</Text>            
                <Text className={Styles.afterDash}>{afterDash}</Text>
                <Text className={Styles.fiscal}> {props.firstFiscalYear && props.firstFiscalYear} - {props.firstFiscalQtr && props.firstFiscalQtr.split(' ')[1]} </Text>
              </Heading>
            </Box>
            
            <Box id='excludeId' className={`${Styles.footer} ${' excludeOP'}`}>
              <Image src={FooterImg} width={'60px'} float={'left'} alt={beforeDash} />
              <Text as={'span'} className={Styles.copy}> © 2025 Cisco and/or its affiliates. All rights reserved. Cisco confidential</Text>
            </Box>
          </Box>
          <Box id='footerPdf' className={`${Styles.footer}`}>
            <Image src={FooterImg} width={'60px'} float={'left'} alt={beforeDash} />
            <Text as={'span'} className={Styles.copy}> © 2025 Cisco and/or its affiliates. All rights reserved. Cisco confidential </Text>
          </Box>
        </>
    )
}

export default SectionZero
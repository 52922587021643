import React, { useState, useEffect, useRef, useContext } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import API from '../../../../../Services/API';
import BreadCrumbs from '../../../../../Components/Widgets/BreadCrumbs/BreadCrumbs';
import { Heading, Card, CardBody, Box, Image, Input, Button, Code, useToast } from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { EditContext } from '../../../../../Context/BreadcrumbsContext';
import MetricData from '../../../../../Components/Modules/FiscalYears/FiscalYearsQTR/MetricData/MetricData';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const YearQTR = () => {
  const token = window.localStorage.getItem('accessToken');
  const { qtrid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [pageInfo, setPageInfo] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [file, setFile] = useState(null);
  const editor = useRef(null);
  const toast = useToast();
  const { edit } = useContext(EditContext);
  const [isMessage, setIsMessage] = useState(null);

  // Fetch and set initial data
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    API.get(`/fiscal-year-quarter/?id=${qtrid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const data = response.data.results[0];
        setPageInfo(data);
        setName(data.name || '');
        setDescription(data.description || '');
        setNote(data.note || '');
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [qtrid, token]);

  const updateQtr = () => {
    setIsMessage(null)
    // Check if changes are made
    if (
      name === pageInfo?.name &&
      description === pageInfo?.description &&
      note === pageInfo?.note &&
      !file
    ) {
        setIsMessage("No changes are made.")
        return;
    }

    // Update data
    setIsLoading(true);
    const formData = new FormData();
    formData.append('id', parseInt(qtrid));
    if (file) {
      formData.append('banner', file);
    }
    formData.append('name', name);
    formData.append('description', description);
    formData.append('note', note);

    API.put(`/fiscal-year-quarter/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        API.get(`/fiscal-year-quarter/?id=${qtrid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            const updatedData = response.data.results[0];
            setPageInfo(updatedData);
            setIsMessage("Quarter updated successfully.")
          })
          .catch(() => {
            setIsMessage("Failed to fetch updated data.")
          });
      })
      .catch(() => {
        setIsMessage("Error updating quarter.")
      })
      .finally(() => setIsLoading(false));
  };

  const bannerUpload = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {
        isMessage && (
          <ErrorWidget message={isMessage} />
        )
      }
      <BreadCrumbs title={pageInfo?.name} />
      <Card>
        <CardBody>
          <Box>
            {edit ? (
              <>
                <Input
                  placeholder="Enter quarter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size="md"
                  mb="15px"
                />
                <Input type="file" onChange={bannerUpload} mb="15px" padding="4px" />
                <JoditEditor
                  ref={editor}
                  config={{
                    readonly: false,
                    tabIndex:"0"
                }}
                  value={description}
                  tabIndex={1}
                  onChange={(content) => setDescription(content)}
                />
                <Input
                  placeholder="Add additional notes"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  size="md"
                  mb="15px"
                  mt="15px"
                />
                <Button
                  className="primary primaryButtonNew"
                  mt="15px"
                  onClick={updateQtr}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Heading as="h2" size="lg" mb="25px">
                  {pageInfo?.name}
                </Heading>
                {pageInfo?.banner && (
                  <Image
                    src={pageInfo.banner}
                    width="100%"
                    alt={pageInfo.name}
                    borderRadius="lg"
                  />
                )}
                {pageInfo?.description && parse(pageInfo.description)}
                <Code>{pageInfo?.note}</Code>
              </>
            )}
          </Box>
          <MetricData type="qtr" />
        </CardBody>
      </Card>
    </>
  );
};

export default YearQTR;

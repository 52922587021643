import React,{useContext, useEffect, useState} from 'react'
import API from '../../../../../../../../Services/API'
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { Box, Button, Text } from '@chakra-ui/react'
import { EditContext } from '../../../../../../../../Context/BreadcrumbsContext'
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget';

const Status = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const {edit} = useContext(EditContext);
  const { actionid } = useParams();
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [actionId, setActionId] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusId, setStatusId] = useState(null);
  //const {user} = useContext(EditContext);
  const [user, setUser] = useState({})
  
  useEffect(()=>{
    API.get(`/get-specific-modules?module_id=${actionid}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        setUser(response.data);
    }).catch(error => {
    });
    API.get(`/pillar?module_id=${actionid}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setActionId(response.data.id)
      API.get(`/qbr-screen-status?screen_no=${props.screen}&action_id=${response.data.id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        setStatusId(response.data[0].id)
        setStatus(response.data[0].screen_status)
      }).catch(error=>{
      })
    }).catch(error=>{
    })
  },[])

  const ACTIONSTATUS_CHOICES = [
    { value: 'on-track', label: 'On-track' },
    { value: 'delayed', label: 'Delayed' },
    { value: 'complete', label: 'Complete' },
  ];

  const handleChange = (selectedOption) => {
    setSelectedChoice(selectedOption);
  };

  const saveAction = async () => {
    setSuccessMessage(null)
    setIsLoading(true);
    if (!selectedChoice){
      setSuccessMessage('Please selct status')
      setIsLoading(false);
      return
    }
    if(status !== null){
      
      const body = {
        "id":statusId,
        "screen_status": selectedChoice.value,
        "screen": props.screen,
        "selectedfy": props.selectedFiscalYearID,
        "action": Number(actionId),
        "module_id": Number(actionid),
        approval_status: (user.superuser || user.action_office_user || user.position === "action_owner") ? "Approved" : "Ready to Submit",
      }
      API.put(`/qbr-screen-status`, body, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
        API.get(`/qbr-screen-status?screen_no=${props.screen}&action_id=${actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          setStatusId(response.data[0].id)
          setStatus(response.data[0].screen_status)
          props.getUpdate(response.data[0].approval_status);
        }).catch(error=>{
        })
        setSuccessMessage("Successfully assigned/changed status")
        setIsLoading(false);
      }).catch(error=>{
        setIsLoading(false);
        setSuccessMessage(
          error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
          error.response.data.msg ? error.response.data.msg : 'Facing technical error'
        )
      })
    }else{
      const body = {
        "screen_status": selectedChoice.value,
        "screen": props.screen,
        "selectedfy": props.selectedFiscalYearID,
        "action": Number(actionId),
        "module_id": Number(actionid),
        approval_status: (user.superuser || user.action_office_user || user.position === "action_owner") ? "Approved" : "Ready to Submit",
      }
      API.post(`/qbr-screen-status`, body, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
        API.get(`/qbr-screen-status?screen_no=${props.screen}&action_id=${actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          setStatusId(response.data[0].id)
          setStatus(response.data[0].screen_status)
          props.getUpdate(response.data[0].approval_status);
        }).catch(error=>{
        })
        setSuccessMessage("Successfully assigned/changed status")
        setIsLoading(false);
      }).catch(error=>{
        setIsLoading(false);
        setSuccessMessage(
          error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
          error.response.data.msg ? error.response.data.msg : 'Facing technical error'
        )
      })
    }
  }

  const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#ffffff",
        border: "1px solid #63b3ed",
        borderRadius: "8px",
        padding: "5px",
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#ffffff", // White placeholder color
        fontSize: "14px",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ffffff", // Ensures the selected value is also white
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#63b3ed" : state.isFocused ? "#63b3ed" : "#ffffff",
        color: state.isSelected ? "#ffffff" : state.isFocused ? "#ffffff" : "#333333",
        padding: "10px",
        cursor: "pointer",
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#f0f8ff",
        borderRadius: "8px",
    }),
};

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {successMessage && <ErrorWidget message={successMessage} />}
      <Box float={'right'} fontSize={'13px'} width={'280px'}>
          <>
            {
              edit ? 
              <>
                <Text mb={'5px'}> Action execution status :  </Text>
                <Select
                  options={ACTIONSTATUS_CHOICES}
                  onChange={handleChange}
                  placeholder="Please select the status"
                  defaultValue={ACTIONSTATUS_CHOICES.find(choice => choice?.value === (status))}
                  styles={customStyles}
                />
                <Button
                  onClick={saveAction}
                  disabled={isLoading || !selectedChoice || selectedChoice?.value === props.actionStatus}
                  ml={'0px'}
                  colorScheme='blue'
                  mt={'5px'}
                >
                  Save
                </Button>
              </>
              : <Box float={'right'}><Text as={'span'}> Action execution status  :  </Text> {status && <Text textTransform={'capitalize'} as={'span'} backgroundColor={status === 'delayed' ? 'red' : status === 'on-track' ? '#00c7ee' : 'green' } p={'5px 10px'}> {status} </Text>}</Box>
            }
          </>
      </Box>
      <Box claer='both' float={'left'} width={'100%'} mb={'15px'}></Box>
    </>
  )
}

export default Status
import React,{useState} from 'react'
import $ from 'jquery'
import Styles from './addNewTag.module.css'
import API from '../../../../../Services/API'
import { PhoneIcon, AddIcon, WarningIcon, } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea 
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget'

const AddNewTag = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [addAnother, setAddAnother] = useState(false)
    const [error, setError] = useState(false)
    const [errorCount, setErrorCount] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(false)

    
    
    const saveAndAddNew = (event) =>{
        setAddAnother(event.target.checked)
    }

    const newTag = () => {
        setMessage(null)
        setError(null)
        setErrorCount(false)
        setIsLoading(true)
        const formData = new FormData()
        // formData.append('tag_info', props.id)
        formData.append('name', $("#tagName").val())
        API.post(`/tag-detail/`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setMessage('Added new tag successfully')
            if(addAnother){
                onOpen()
                $("#prop_label").val('')
                $("#article_name").val('')
                setIsLoading(false)
            }else{
                onClose()
                setIsLoading(false)
            }
            API.get(`/tag-detail/`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                props.getUpdatedList(response.data.results)
            }).catch(error=>{
                setIsLoading(false)
            })
            
        }).catch ((error) => {
            setErrorCount(true)
            setError(
                error.response.data.tag_info ? error.response.data.tag_info[[0]] : 
                error.response.data.name ? error.response.data.name[0] : error.response.data.error
            );
            if(error.response.data.error){
                onClose()  
            }
            setIsLoading(false)
        })
    }
    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {message ? <ErrorWidget message={message} /> : null}
            {
                errorCount ? <ErrorWidget message={`${error.charAt(0).toUpperCase()}${error.slice(1)}`} /> : null
            }        

            <Box className='cardHover' mb={'30px'}>
                <Card tabIndex={"0"}
          sx={{
            cursor: "pointer",
            transition: "0.3s",
            "&:focus": {
              outline: "2px solid #00AAE0", // Highlight border on focus
              boxShadow: "0 0 5px rgba(0, 170, 224, 0.7)", // Optional glow effect
            },
          }}
          onKeyDown={(event) => {
            // Only handle Enter key when the card is focused and not the delete icon
            if (event.key === "Enter" && event.target === event.currentTarget) {
              event.preventDefault();   
              event.stopPropagation();
             onOpen();
            }
          }} height='100px' bg='white' position={'relative'} cursor={'pointer'} onClick={onOpen}>
                    <Heading 
                        as='h1' 
                        size='sm' 
                        noOfLines={1} 
                        position={'relative'} 
                        top={'50%'} 
                        className={Styles.verticalAlign}
                        textAlign={'center'}
                    >
                        <AddIcon boxSize={3} /> Add New
                    </Heading>
                </Card>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalHeader>Add new Tag</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                        
                            
                            <FormLabel>Title</FormLabel>
                            <Input type='text' id={'tagName'} mb={'15px'} />
                            <Stack spacing={5} direction='row' mt={'10px'}>
                                <Checkbox colorScheme='green' defaultChecked={addAnother} onChange={saveAndAddNew}>
                                    Save and Add another
                                </Checkbox>
                            </Stack>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button mr={3} onClick={onClose} backgroundColor={'transparent'}>
                            Cancel
                        </Button>
                        <Button className='primaryButtonNew' onClick={newTag}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddNewTag
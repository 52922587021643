import React, { useState, useEffect } from 'react';
import Styles from '../../MetricData/metricdata.module.css';
import API from '../../../../Services/API';
import Select from 'react-select';
import { FormControl, FormLabel, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {Modal,ModalOverlay,ModalContent,ModalBody,ModalFooter,Button,Tooltip,Alert,AlertIcon,Spinner,useDisclosure} from '@chakra-ui/react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget'


const RejectComponent = ({ id, getMetricInfo, url }) => {
  const token = window.localStorage.getItem('accessToken');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  // Reset form when modal opens or closes
  useEffect(() => {
    if (isOpen) {
      setSelectedOption(null);
      setMessage(null);
    }
  }, [isOpen]);

  const reject = async () => {
    setMessage(null)
    setIsLoading(true);
    if (!selectedOption) {
      setMessage('Please select a reason before confirming.');
      setIsLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('approval_status', 'rejected');
      formData.append('reject_reason', selectedOption.value);
      formData.append('metric_url', url);
      setIsLoading(true);
      const response = await API.put('/metric', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('The metric details have been rejected.');
      getMetricInfo();
      onClose();
    } catch (err) {
      setMessage(err.response.data.msg ? err.response.data.msg : 'Failed to reject metric. Please try again.');
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const options = [
    { value: 'Aggregate Type incorrect', label: 'Aggregate Type incorrect' },
    { value: 'Data Type incorrect (percent, number, money)', label: 'Data Type incorrect (percent, number, money)' },
    { value: 'Incorrect Executive Leadership Review (ELR) Settings', label: 'Incorrect Executive Leadership Review (ELR) Settings' },
    { value: 'Incorrect External Data Setting', label: 'Incorrect External Data Setting' },
    { value: 'Incorrect Map Settings', label: 'Incorrect Map Settings' },
    { value: 'Metric Type incorrect (operational, financial, impact)', label: 'Metric Type incorrect (operational, financial, impact)' },
    { value: 'Strategy Incorrect', label: 'Strategy Incorrect' },
    { value: 'Tag(s) incorrect', label: 'Tag(s) incorrect' },
    { value: 'other', label: 'Other' },
  ];

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  return (
    <>
      {isLoading ?  <LoaderSpinner /> : null}
      {message ?  <ErrorWidget message={message} /> : null}
      <Tooltip label="Reject Metric" aria-label="Reject Metric">
        <IconButton
          variant="solid"
          aria-label="Reject"
          fontSize="15px"
          icon={<CloseIcon />}
          mr={'5px'}
          className={Styles.edit}
          onClick={onOpen}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay className={Styles.overLay} />
        <ModalContent className={Styles.contentZindex}>
          <ModalBody>
            <FormControl>
              <FormLabel fontWeight={'600'}>Please select the reason</FormLabel>
            </FormControl>
            <Select
              options={options}
              value={selectedOption}
              onChange={handleChange}
              placeholder="Select a reason"
            />
          </ModalBody>
          <ModalFooter className={Styles.modelFooter}>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              className={Styles.confirmButton}
              onClick={reject}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RejectComponent;
